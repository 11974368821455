import React, { useState } from 'react';
import { Button, Form, } from "react-bootstrap";
import PSSession from '../common/PSSession';
import PSFlush from '../common/PSFlush';
import { router } from '../common/PSRouter';
import styles from './PSStoreUserUnsubscribeComponent.module.scss'

export default function PSStoreUserUnsubscribeComponent({api_base_url}) {
  const [reasonType, setReasonType] = useState(null);
  const [message, setMessage] = useState("");

  const onSubmit = async (e) => {
    e.preventDefault();
    const success = await PSSession.unsubscribe({reasonType, message, apiBaseUrl: api_base_url});
    if (success) {
      PSFlush.set({notice: ['ご利用ありがとうございました']});
      router.push("/");
    }
  }

  return (
    <div className={styles.content}>
      <div>
        <h1>退会お手続き</h1>
      </div>
      <div className={styles.caution}>
        退会にあたり、下記のご注意をご確認ください。
        <ul>
          <li>ご購入情報が消去されますので、楽譜などコンテンツの再ダウンロードができなくなります。ご購入した楽譜については<a href="#">購入済み楽譜</a>よりご確認ください。</li>
          <li>クレジットカード情報につきまして、弊社は決済サービス <a href="https://pay.jp/" target="_blank">PAY.JP</a> を利用しており、弊社側では、クレジットカード情報は一切保存しておりません。また、決済代行会社である PAY.JP によりますと、カード情報を扱う規定上、カード情報の保管期限と破棄の徹底に関する要件がございまして、取引より7年間は監査上保管する必要がございます。</li>
          <li>今後、同じアカウント（メールアドレス）での、アカウントの再作成ができなくなる場合がございます。</li>
        </ul>
      </div>

      <div className={styles.form}>
        <h2>退会理由についてご回答ください</h2>

        <Form onSubmit={onSubmit}>
          <Form.Group>
            <Form.Check type="radio" name="reason" onChange={() => setReasonType("none")} label="なし" required className={styles.checkbox} />
            <Form.Check type="radio" name="reason" onChange={() => setReasonType("no_use")} label="使わなくなった" className={styles.checkbox} />
            <Form.Check type="radio" name="reason" onChange={() => setReasonType("hard")} label="使いづらかった" className={styles.checkbox} />
            <Form.Check type="radio" name="reason" onChange={() => setReasonType("payment")} label="決済方法が合わなかった" className={styles.checkbox} />
            <Form.Check type="radio" name="reason" onChange={() => setReasonType("no_want")} label="欲しい楽譜が見つからなかった" className={styles.checkbox} />
            <Form.Check type="radio" name="reason" onChange={() => setReasonType("other")} label="その他" className={styles.checkbox} />
          </Form.Group>

          <Form.Group>
            <Form.Control as="textarea"  className={styles.message}
              value={message} onChange={e => setMessage(e.target.value)}
              placeholder="自由にご意見・ご要望などをお書きください" />
            </Form.Group>
          <Form.Group className={styles.submit_button}>
            <Button type="submit" variant="danger">
            退会する
            </Button>
          </Form.Group>
        </Form>
      </div>
    </div>
  );
}