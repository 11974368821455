import React from 'react';
import { router } from '../common/PSRouter';
import PSSession from '../common/PSSession';
import styles from './PSStoreLogoutComponent.module.scss'

export default function PSStoreLogoutComponent({api_base_url}) {

  const logout = async(e) => {
    e.preventDefault();
    await PSSession.logout({apiBaseUrl: api_base_url});
    router.push("/");
  }

  return (
    <div className={styles.logout}>
      <a onClick={(e) => logout(e)}>ログアウト</a>
    </div>
  );
}