//
//  Simple Routing library

const push = (url) => {
  history.pushState({}, "");
  location.replace(url);
}

const reload = () => {
  location.reload();
}

export const router = { push, reload };
