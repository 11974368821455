import React, { useEffect } from 'react';
import PSSession from '../common/PSSession';
import PSFlush from '../common/PSFlush';
import PSURLSearchParams from '../common/PSURLSearchParams';
import { router } from '../common/PSRouter';

export default function PSStoreConfirmChangeMailAdress({api_base_url}) {
  const apiBaseUrl = api_base_url;

  useEffect(() => {
    (async() => {
      const hash = PSURLSearchParams.get("v");
      if (hash) {
        try {
          const newMailAdress = await PSSession.confirmMailAddressChange({hash, apiBaseUrl});
          if (newMailAdress) {
            PSFlush.set({notice: ['メールアドレスが変更されました']});
            await PSSession.logout({hash, apiBaseUrl});
            router.push(`/login?mail_address=${newMailAdress}`);
            return;
          }
        } catch (err) {
          PSFlush.set({error: ['メールアドレスが変更できませんでした。再度、メールアドレス変更の手続きを行ってください。']});
        }
      }
      PSFlush.set({error: ['メールアドレスが変更できませんでした。再度、メールアドレス変更の手続きを行ってください。']});

      if (await PSSession.checkLogind({apiBaseUrl})) {
        router.push("/user");
      } else {
        router.push("/login");
      }
    })()
  }, []);


  return null;
}