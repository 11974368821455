//
// Simple Flush library
import ReactDOMServer from 'react-dom/server';

const PSFlushSeesionStorageKey = "com.piascore.flush";
const FlushExpirationTime = 1 * 60 * 60 * 1000;    // milliseconds

const PSFlush = {
  set: (value) => {
    sessionStorage.setItem(PSFlushSeesionStorageKey, JSON.stringify({value, timesamp: Date.now()}))
  },

  get: () => {
    const flush = JSON.parse(sessionStorage.getItem(PSFlushSeesionStorageKey));
    if (flush == null) return {};

    sessionStorage.removeItem(PSFlushSeesionStorageKey);

    if (Date.now() - flush.timesamp > FlushExpirationTime) {
      return {};
    } else {
      return flush.value;
    }
  },

  jsx2html: (jsx) => {
    return {__html: ReactDOMServer.renderToStaticMarkup(jsx)};
  },

  isHtml: (value) => {
    return (value instanceof Object) && Boolean(value['__html']);
  }
}


export default PSFlush;