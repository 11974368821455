// 配列にkey

// turbolinks
// NG:turbolink:false の設定      = link_to "お問い合わせフォーム", contact_path, data: { turbolinks: false }
// OK:    = javascript_pack_tag 'application' の位置（turbolinks 以下）

import React from 'react';
import { useState, useEffect, useRef } from 'react'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleRight, faInfoCircle, faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";

// css https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css
// js https://cdnjs.cloudflare.com/ajax/libs/react-bootstrap/2.1.2/react-bootstrap.min.js 
//const { Button, Modal, Form, Container, Row, Col, Alert }  = ReactBootstrap;
import { Button, Modal, Form, Container, Row, Col, Alert, Table } from 'react-bootstrap';

// import ReCAPTCHA from "react-google-recaptcha";
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import PSAPIClient    from './common/PSAPIClient.js'

//import './PSStoreContactComponent.scss'; 
import styles from './PSStoreContactComponent.module.scss'

const PSStoreContactScoreInputComponent = function({apiBaseURL, onChangeScores}) {

  const [scores, setScores] = useState([])
  const [isFound, setIsFound] = useState(true)
  
  useEffect(() => {
    onChangeScores(scores)
  }, [scores])
  
  const findScore = async function(e) {
    const scoreId = e.target.value
    if(isNaN(parseInt(scoreId))) { setIsFound(false); return }
    
    const res = await PSAPIClient.get({url:apiBaseURL + `/scores/${scoreId}`})
    const score = res.body
    
    if(!score || !score.score_id) { setIsFound(false); return }
    
    setIsFound(true)
    
    //scores.push(score)
    //console.log(scores.length)
    setScores([...scores, score])
  }

  const deleteScore = function(index) {
    const scores2 = scores.filter((_, i) => i !== index)
    setScores(scores2);
  }
  
  const ScoreTRComponent = function({score, index, onDelete}) {
    
    const deleteItem = function(e) {
      //console.log(e.target.value)
      
      onDelete(parseInt(e.target.value))
    }
    
    return (
      score ? 
      (
        <tr key={index}>
          <td style={{textAlign:"right"}}>{index + 1}</td>
          <td>{score.score_id}</td>
          <td><a target="_blank" href={`/scores/${score.score_id}`}>{ score.regions.ja.name }</a></td>
          <td>
            <div style={{textAlign:"right"}}>¥{ score.distribute.jpy_price_with_tax.toLocaleString() }</div>
            <div style={{marginTop:10, textAlign:"center"}}><Button variant="danger" style={{padding:5, fontSize:11}} value={index} onClick={deleteItem}>削除</Button></div>
          </td>
        </tr>
      )
      :
      (
        <tr key={index}>
          <td style={{textAlign:"right"}}>{index + 1}</td>
          <td colSpan="3">
            <div style={{display:"flex", alignItems:"center"}}>
              <div style={{display:"flex", alignItems:"center"}}>
                <Form.Control style={{width:100}} className={`rounded-4`} onBlur={findScore} name="score_id" id="score_id" type="text" placeholder="12345" />
                <div style={{marginLeft:5}}>
                  <Button style={{padding:5, fontSize:11}}>追加</Button>
                </div>
              </div>
              <div style={{marginLeft:10}}>
                {isFound ? "" : "見つかりませんでした"}
              </div>
            </div>
          </td>
        </tr>
      )
    )
  }
  
  

  return (
    <>
      <Table striped bordered hover>
        <thead>
          <tr style={{fontSize:14}}>
            <th style={{width:35}}></th>
            <th style={{width:80}}><a target="_blank" href="https://help.piascore.com/hc/ja/articles/4407712683289">楽譜ID</a></th>
            <th>楽譜名</th>
            <th style={{width:80}}>価格（税込）</th>
          </tr>
        </thead>
        <tbody>
          {
            scores.length > 0 ?
              scores.map((s, index) => (
                <>
                  <ScoreTRComponent key={index+2} score={s} index={index} onDelete={deleteScore} />
                </>
              ))
            :
            ""
          }
          <>
            <ScoreTRComponent key={scores.length+1} score={null} index={scores.length} />
          </>
          <tr>
            <td colSpan="4" style={{textAlign:"right"}}>
              計 ¥{scores.reduce((acc, score) => { return acc + score.distribute.jpy_price_with_tax }, 0).toLocaleString() }
            </td>
          </tr>
        </tbody>
      </Table>
    </>
  )
}

const FormHeader = props => {
  return (
    <div className={styles.contact_header}>
      <div className={styles.title}>お問合せフォーム</div>
      <FormCaution />
    </div>
  )
}

const FormCaution = props => {
  return (
    <Row style={{"padding":"20px 20px 0px", "fontSize":"14px"}}>
      <Col>
        <div className={`alert alert-secondary`} style={{"fontSize":"14px"}}>
          ご連絡後、3日以内にご返信差し上げます。
          <br /><br />
          返信が来ない場合、お客様のメールにフィルターなどが設定されている可能性がございます。
          <br /><br />
          <span style={{color:"#f3012d"}}>piascore.com ドメインからのメールが受信できるようにフィルターが設定されていること</span>を確認いただき、ゴミ箱などに返信が届いていないかご確認ください。
        </div>
      </Col>
    </Row>
  )
}

//class FormBody extends React.Component {
function FormBody({isLogined, opinionType, apiBaseURL}) {

  const [htmlMsg, setHTMLMsg] = useState("")
  const [message, setMessage] = useState("")
  //const [showModal, setShowModal] = useState(false)
  const [cautionHTML, setCautionHTML] = useState(<></>)
  const [opinionTypeState, setOpinionTypeState] = useState(opinionType || "none")

  const [showSubmitAlert, setShowSubmitAlert]   = useState(false)
  const [disableSubmit, setDisableSubmit]       = useState(true)

  const [isPayByBank, setIsPayByBank] = useState(opinionType=="pay_by_bank")

  // const [recaptchaValue, setRecaptchaValue] = useState(null);
  const { executeRecaptcha } = useGoogleReCaptcha();

  // タイマーIDを保持するためのref
  //let timerRef = null;
  const timerRef = useRef(null);
  
  useEffect(() => {
    showMessage(opinionTypeState)
  }, [])

  /*
  const handleOnSubmit = (e) => {

    // if (disableSubmit) { return }
    if (disableSubmit || !recaptchaValue) { 
      e.preventDefault();
      return;
    }

    setDisableSubmit(true)
    //this.setState({ disableSubmit: true });
  }
    */
  
  const handleOnSubmit = async (e) => {
    e.preventDefault();
    
    if (disableSubmit) { return; }

    try {
      // reCAPTCHA v3 のトークンを取得
      const token = await executeRecaptcha('contact_form');
      if (!token) {
        console.error('reCAPTCHA token not available');
        return;
      }

      // フォームの送信処理
    // hidden フィールドを追加してトークンを設定
    const form = e.target;
    const hiddenInput = document.createElement('input');
    hiddenInput.type = 'hidden';
    hiddenInput.name = 'g-recaptcha-response';
    hiddenInput.value = token;
    form.appendChild(hiddenInput);
    
      // フォーム送信を実行
      form.submit();
      
    } catch (error) {
      console.error('reCAPTCHA error:', error);
    }
  }

  
  
  // const onRecaptchaChange = (value) => {
  //   setRecaptchaValue(value);
  // }


  const modifyMessage = function(msg) {
    
    // common normalization
    {
      msg = hankana2Zenkana(msg);

      // 誤字修正
      {
        msg = msg.replace(/こんびに/g, "コンビニ")
        msg = msg.replace(/ひょうじ/g, "表示")
        msg = msg.replace(/なにも/g, "何も")
        msg = msg.replace(/わかりません/g, "分かりません")
      }
    }

    msg = msg.replace(/💢/g, "")
    msg = msg.replace(/⁉️/g, "？")
  
    return msg
  }
  
  const createCautionHTML = function(cautions) {
    
    if(!cautions || cautions.length == 0) { return null }
    
    return (
      <>
        {
          cautions.map((c, index) => (
            <Alert className={styles.message_alert} key={index} variant="warning">
              <Alert.Heading className={`${styles.alert_heading}`}>
                <span style={{"padding":"0 3px 0 0"}}>
                  <FontAwesomeIcon style={{ padding: 0, fontSize: 16 }} icon={faInfoCircle} />
                </span>
                {c.title}
              </Alert.Heading>
              <div>{c.msg}</div>
              { c.btn_text!=null &&
                <>
                  <hr />
                  <div className={`d-flex justify-content-end`}>
                    <a target="_blank" href={c.btn_url}>
                      <Button size="sm" variant="warning" style={{padding:"15px 15px"}}>
                        {c.btn_text} 
                        <span style={{"padding":"0 0 0 3px"}}>
                          <FontAwesomeIcon style={{ padding: 0, fontSize: 15 }} icon={faAngleRight} />
                        </span>
                      </Button>
                    </a>
                  </div>
                </>
              }
            </Alert>
          ))
        }
      </>
    );
  }
  
  const handleChange = (e) => {

    const changedMsg = modifyMessage(e.target.value)
    setMessage(changedMsg)
    
    const cautions = getCautions(changedMsg)

    // let disableSubmit = false;
    // cautions.forEach(c => {
    //   if (c.disableSubmit) {
    //     disableSubmit = true;
    //     //break;
    //   }
    // })

    let cautionHTML = createCautionHTML(cautions)
    setCautionHTML(cautionHTML)

    if (timerRef.current) { clearTimeout(timerRef.current); }
    if(cautions.length > 0) {
      // タイマーをセット
      timerRef.current = setTimeout(() => {
        setShowSubmitAlert(true)
      }, 20000);
      setDisableSubmit(true)
    }else{
      setShowSubmitAlert(false)
      setDisableSubmit(false)      
    }
    //setDisableSubmit(disableSubmit)
  }

  const hankana2Zenkana = function(str) {
    
    str = str.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function(s) {
      return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
    });
  
    var kanaMap = {
        'ｶﾞ': 'ガ', 'ｷﾞ': 'ギ', 'ｸﾞ': 'グ', 'ｹﾞ': 'ゲ', 'ｺﾞ': 'ゴ',
        'ｻﾞ': 'ザ', 'ｼﾞ': 'ジ', 'ｽﾞ': 'ズ', 'ｾﾞ': 'ゼ', 'ｿﾞ': 'ゾ',
        'ﾀﾞ': 'ダ', 'ﾁﾞ': 'ヂ', 'ﾂﾞ': 'ヅ', 'ﾃﾞ': 'デ', 'ﾄﾞ': 'ド',
        'ﾊﾞ': 'バ', 'ﾋﾞ': 'ビ', 'ﾌﾞ': 'ブ', 'ﾍﾞ': 'ベ', 'ﾎﾞ': 'ボ',
        'ﾊﾟ': 'パ', 'ﾋﾟ': 'ピ', 'ﾌﾟ': 'プ', 'ﾍﾟ': 'ペ', 'ﾎﾟ': 'ポ',
        'ｳﾞ': 'ヴ', 'ﾜﾞ': 'ヷ', 'ｦﾞ': 'ヺ',
        'ｱ': 'ア', 'ｲ': 'イ', 'ｳ': 'ウ', 'ｴ': 'エ', 'ｵ': 'オ',
        'ｶ': 'カ', 'ｷ': 'キ', 'ｸ': 'ク', 'ｹ': 'ケ', 'ｺ': 'コ',
        'ｻ': 'サ', 'ｼ': 'シ', 'ｽ': 'ス', 'ｾ': 'セ', 'ｿ': 'ソ',
        'ﾀ': 'タ', 'ﾁ': 'チ', 'ﾂ': 'ツ', 'ﾃ': 'テ', 'ﾄ': 'ト',
        'ﾅ': 'ナ', 'ﾆ': 'ニ', 'ﾇ': 'ヌ', 'ﾈ': 'ネ', 'ﾉ': 'ノ',
        'ﾊ': 'ハ', 'ﾋ': 'ヒ', 'ﾌ': 'フ', 'ﾍ': 'ヘ', 'ﾎ': 'ホ',
        'ﾏ': 'マ', 'ﾐ': 'ミ', 'ﾑ': 'ム', 'ﾒ': 'メ', 'ﾓ': 'モ',
        'ﾔ': 'ヤ', 'ﾕ': 'ユ', 'ﾖ': 'ヨ',
        'ﾗ': 'ラ', 'ﾘ': 'リ', 'ﾙ': 'ル', 'ﾚ': 'レ', 'ﾛ': 'ロ',
        'ﾜ': 'ワ', 'ｦ': 'ヲ', 'ﾝ': 'ン',
        'ｧ': 'ァ', 'ｨ': 'ィ', 'ｩ': 'ゥ', 'ｪ': 'ェ', 'ｫ': 'ォ',
        'ｯ': 'ッ', 'ｬ': 'ャ', 'ｭ': 'ュ', 'ｮ': 'ョ',
        '｡': '。', '､': '、', 'ｰ': 'ー', '｢': '「', '｣': '」', '･': '・'
    };

    var reg = new RegExp('(' + Object.keys(kanaMap).join('|') + ')', 'g');
    return str
            .replace(reg, function (match) {
                return kanaMap[match];
            })
            .replace(/ﾞ/g, '゛')
            .replace(/ﾟ/g, '゜');
  };

  const getCautions = function(msg){
    
    if (!msg) {
      //return [<></>];
      return [];
    }
    
    msg = msg.toLowerCase()

    let DEBUG = false;
    //let DEBUG = true;
    
    let cautions = [];
    
    // 絵文字対策
    if ( DEBUG || (msg.match(/💢|⁉️/i))) {
      cautions.push({
        title:"内容に絵文字は使えません",
        msg:<>上の「内容」に絵文字は使えません</>,
        btn_text:null,
        btn_url:null
      })
    }
    
    ////////////////////////////////////////////////////////////
    // ユーザ登録

    if ( DEBUG || (msg.match(/会員登録|仮登録|仮メール/i)) || (msg.match(/登録/i) && msg.match(/完了/i))) {
      cautions.push({
        title:"仮登録のメールが届かない",
        msg:<>メールが届かない場合は、ゴミ箱を確認いただくか、フィルタ設定をご確認の上で<a href="/sign_up">ユーザー登録</a>より再度登録し直してください。</>,
        btn_text:null,
        btn_url:null
      })
    }

    if ( DEBUG || ( msg.match(/ログイン/i) && msg.match(/できず|出来ず|できない|できません|登録していません|登録が完了していない/i) ) ) {
      cautions.push({
        title:"ログインできない",
        msg:
          <>以下の可能性がございます。
            <div style={{"fontWeight":"bold", "margin":"20px 0 0 0"}}>メールアドレス/パスワードが間違っている</div>
            <div><a href="/request_password_reset">パスワードのリセット</a>を行ってください</div> 
            <div style={{"fontWeight":"bold", "margin":"20px 0 0 0"}}>登録が完了していないなどの理由により無効なユーザ</div>
            <div>仮登録時に送られるメール内のリンクをクリックして登録を完了するか、<a href="/sign_up">ユーザー登録</a>より再度登録し直してください</div>
          </>,
        btn_text:null,
        btn_url:null
      })
    }

    ////////////////////////////////////////////////////////////
    // ログインパスワード
    
    if ( DEBUG || msg.match(/パスワード|pw/i)) {
      if ( DEBUG || msg.match(/会員|ログイン|再設定/i)) {
        cautions.push({
          title:"ログイン時のパスワードがわからない、または再設定したい",
          msg:<><span style={{"fontWeight":"bold"}}>会員登録がお済みの場合</span><br /><a target="_blank" href="/request_password_reset">パスワードリセット</a>より、パスワードの再設定を行って下さい。<br /><br /><span style={{"fontWeight":"bold"}}>会員登録がお済でない場合</span><br />会員登録が完了していない場合がございます。「[Piascore] 仮登録のお知らせ」というタイトルのメール内にあるリンクをクリックし、会員登録を完了して下さい。<br /><br />届いていない場合は、本フォームよりご連絡下さい。</>,
          btn_text: null,
          btn_url: null
        })
      }

      if ( DEBUG || msg.match(/変更|変異/i)) {
        cautions.push({
          title:"ログイン時のパスワードを変更したい",
          msg:
          <>
            <ul>
              <li>会員登録がお済みの場合<ul><li>ログインして、<a target="_blank" href="/user/show">会員情報の変更</a>より変更してください</li></ul></li>
              <li>会員登録がお済みか分からない場合
                <ul>
                  <li>仮登録がお済みの場合は、本登録へのメールが届いていないかご確認ください。届いていない場合は、ご連絡ください。</li>
                  <li>仮登録していない場合は、<a target="_blank" href="/sign_up">会員情報の登録</a>よりアカウントを作成してください。</li>
                </ul>
              </li>
            </ul>
          </>,
          btn_text: null,
          btn_url: null
        })
      }
    }

    ////////////////////////////////////////////////////////////
    // 楽譜検索

    if ( DEBUG || (msg.match(/楽譜/i) && msg.match(/見当た|見つか/i) )) {
      cautions.push({
        title:"ほしい楽譜が見つからない",
        msg:<>検索して見つからない場合、販売取り下げなどの理由によりご購入できない可能性がございます。<br /><br /> <a target="_blank" href="/req">曲リクエスト</a>よりご要望頂けると、今後販売される場合がございます。 </>,
        btn_text:null,
        btn_url:null
      })
    }

    
    ////////////////////////////////////////////////////////////
    // 購入

    // 購入キャンセル
    if ( DEBUG || (msg.match(/購入/i) && msg.match(/返金|キャンセル/i))) {
      cautions.push({
        title:"購入をキャンセルしたい",
        msg:<>申し訳ございませんが、ダウンロードの販売の性質上、楽譜上の明らかな誤りなどの場合を除き、ご購入後のご返金については対応いたしかねます。</>,
        btn_text:null,
        btn_url:null
      })
    }

    if ( DEBUG || msg.match(/1円|10円|11円|\\11/i)) {
      cautions.push({
        title:"少額の引き落としについて",
        msg:<>弊社が利用している決済サービス<a target="_blank" href="https://pay.jp/">PAY.JP</a>の仕様により、クレジットカードの有効性確認のために、10〜11円（少額）の支払い・返金処理が行われる場合がございます。<br /><br />詳しくは下記サイトご参考ください。</>,
        btn_text:"クレジットカードの明細に、Piascoreより購入覚えのない11円（少額）の引き落としがありました",
        btn_url:"https://help.piascore.com/hc/ja/articles/360038685551"
      })
    }
    
    if ( DEBUG || msg.match(/ゆうちょ|郵便局/i)) {
      cautions.push({
        title:"ゆうちょ口座へのお振込について",
        msg:<>申し訳ございませんが、ゆうちょ口座へのお振込については対応しておりません。</>,
        btn_text:null,
        btn_url:null
      })
    }

    if ( DEBUG || !isLogined) {
      if(DEBUG || msg.match(/楽譜id|銀行|[0-9]{5,6}/i)) {
      // Not login
        cautions.push({
          title:"銀行振込でのご購入をご希望の方へ",
          msg:<>お手数ですが、ログインした上でお申し込み下さい。</>,
          btn_text:"ログイン",
          btn_url:"/login"
        })
      }
    }else{
      if(DEBUG || (msg.match(/銀行振込/i)) ) {
        cautions.push({
          title:"銀行振込でのご購入をご希望の方へ",
          msg:<>上記のお問い合わせ種別より「銀行振込による購入」を選択して、手続きに進んでください。</>,
          btn_text:null,
          btn_url:null
        })
      }
    }
        
    // クレカ以外での購入
    if(DEBUG || (msg.match(/現金/i) && msg.match(/払/i)) ) {
      cautions.push({
        title:"コンビニでの購入",
        msg:<>コンビニでのご購入には対応しておりません。<a target="_blank" href="https://help.piascore.com/hc/ja/articles/4407712683289">銀行振込</a>をご利用ください。</>,
        btn_text:"銀行振り込みで購入したい",
        btn_url:"https://help.piascore.com/hc/ja/articles/4407712683289"
      })
    }

    if(DEBUG || (msg.match(/セブンイレブンで購入|コンビニでの購入|コンビニで購入/i)) ) {
      cautions.push({
        title:"コンビニでの購入",
        msg:<>申し訳ございませんが、コンビニでのご購入には対応しておりません。<a target="_blank" href="https://help.piascore.com/hc/ja/articles/4407712683289">銀行振込</a>をご利用ください。</>,
        btn_text:"銀行振り込みで購入したい",
        btn_url:"https://help.piascore.com/hc/ja/articles/4407712683289"
      })
    }

    if(DEBUG || (msg.match(/クレジット|カード|クレカ/i) && msg.match(/持って/i)) ) {
      cautions.push({
        title:"クレジットカード以外での購入",
        msg:<>下記をご参照ください。</>,
        btn_text:"クレジットカード以外で決済できますか？",
        btn_url:"https://help.piascore.com/hc/ja/articles/360020473991"
      })
    }

    if ( DEBUG || (msg.match(/メール|購入/i) && msg.match(/2通|2回分|何度も|重複/i))) {
      cautions.push({
        title:"「[Piascore 楽譜ストア] 楽譜の閲覧パスワード」というタイトルのメールが複数回届きました",
        msg:<>ダウンロードボタンが続けて押された、または購入・ダウンロードに使用したブラウザのタブが開いたままになりリロードされたなどの理由ため、メールが複数回届く場合がございます。<br /><br />ブラウザのタブを閉じるなどしてください。</>,
        //btn_text:"",
        //btn_url:""
      })
    }

    if ( DEBUG || (msg.match(/カード情報/i) && msg.match(/止まっ/i))) {
      cautions.push({
        title:"クレジットカード情報が入力できない",
        msg:<>お手数ですが、クレジットカードを変更するか、ブラウザを変更してお試しいただけますでしょうか。<br /><br />解決できない場合、お手数ですが、スクリーンショット画像などを、Piascore サポートチーム（support@piascore.com）宛にお送りいただき、発生している問題などを詳しく教えて頂けますでしょうか。</>,
        // btn_text:"",
        // btn_url:""
      })
    }
    
    if ( DEBUG || (msg.match(/カード/i) && msg.match(/引き落とし|引落|引き落し|引落し/i))) {
      cautions.push({
        title:"クレジットの引落し時期を知りたい",
        msg:<>引落し時期は、お使いのクレジットカードによって異なります。クレジットカード会社様にご確認ください。</>,
        // btn_text:"",
        // btn_url:""
      })
    }
    

    if ( DEBUG || (msg.match(/履歴/i))) {
      cautions.push({
        title:"購入履歴が見たい",
        msg:<>購入履歴については、画面右上の「ユーザ名」をクリックいただき、「購入履歴を見る」をクリックしてください。</>,
        btn_text:"購入履歴はこちら",
        btn_url:"/user/history"
      })
    }

    if ( DEBUG || (msg.match(/購入/i) && msg.match(/反応しません|反応しない/i)) ) {
      cautions.push({
        title:"購入ボタンなどのボタンが反応しない",
        msg:<>ご覧いただくブラウザは何をご利用されていますでしょうか。<br />一度ブラウザを変更してお試しいただければと存じます。<br /><br />上記で解決できない場合は、Piascore サポートチーム support@piascore.com 宛にスクリーンショットをお送りいただけますでしょうか。</>,
        btn_text:null,
        btn_url:null
      })
    }

    if ( DEBUG || (msg.match(/購入/i) && msg.match(/完了/i)) ) {
      cautions.push({
        title:"購入完了しているか知りたい",
        msg:<>ログインいただき、<a target="_blank" href="/user/history">購入履歴</a>よりご確認いただけます</>,
        btn_text:"購入履歴をみる",
        btn_url:"/user/history"
      })
    }

    // CVC
    if ( DEBUG || (msg.match(/CVC/i))) {
      cautions.push({
        title:"クレジット決済時に入力するCVCとはなんですか？",
        msg:<>下記をご参照ください</>,
        btn_text:"CVC番号とはなんですか？",
        btn_url:"https://help.piascore.com/hc/ja/articles/360057115271"
      })
    }
    
    // 海外
    if ( DEBUG || (msg.match(/国外|海外|available in Japan/i) && msg.match(/購入/i))) {
      cautions.push({
        title:"海外から購入できません・Only available in Japanと表示される",
        msg:<>下記をご参照ください</>,
        btn_text:"「Only available in Japan」と表示されて購入ができません",
        btn_url:"https://help.piascore.com/hc/ja/articles/360040541651"
      })
    }
    
    ////////////////////////////////////////////////////////////
    // 領収書

    //if ( DEBUG || (msg.match(/領収書/i) && msg.match(/ダウンロード/i))) {
    if ( DEBUG || (msg.match(/領収書/i) )) {
      cautions.push({
        title:"領収書の発行方法を知りたい",
        msg:<>領収書につきましては、ご購入後にお客様の方でダウンロードしていただくことができます。お宛名は、お客様の方で入れていただくことになります。<br /><br />詳しくは、<a target="_blank" href="https://help.piascore.com/hc/ja/articles/360038933411-%E9%A0%98%E5%8F%8E%E6%9B%B8%E3%81%AE%E7%99%BA%E8%A1%8C%E3%81%AF%E3%81%A7%E3%81%8D%E3%81%BE%E3%81%99%E3%81%8B-">領収書の発行手順</a> をご参照ください</>,
        btn_text:"領収書の発行方法",
        btn_url:"https://help.piascore.com/hc/ja/articles/360038933411-%E9%A0%98%E5%8F%8E%E6%9B%B8%E3%81%AE%E7%99%BA%E8%A1%8C%E3%81%AF%E3%81%A7%E3%81%8D%E3%81%BE%E3%81%99%E3%81%8B-"
      })
    }

    ////////////////////////////////////////////////////////////
    // ダウンロード

    // ダウンロード前
    if ( DEBUG || ((msg.match(/ダウンロード|dl/i) && msg.match(/分かりません|分からない/i)) || (msg.match(/ダウンロード/i) && msg.match(/失敗|エラー|見れな/i) && !msg.match(/印刷/i)) ) ) {
      cautions.push({
        title:"楽譜のダウンロード方法がわからない",
        msg:<>
        ご購入された楽譜のページより、ダウンロードしていただけますようお願いいたします。<br /><br />
        <div style={{margin:8, "fontWeight":"bold"}}>購入した楽譜ページの行き方</div>
        <ol style={{"paddingLeft":"1.5rem"}}>
          <li>ログインしてください</li>
          <li>画面右上のアカウント名をクリックしてください</li>
          <li>会員情報にて「<a href="/user/history">購入履歴を見る</a>」をクリックしてください</li>
          <li>購入した楽譜が表示されますので、購入した楽譜をクリックし、画面右側の「ダウンロード」ボタンを押して、ダウンロードしてください</li>
        </ol>
        </>,
        // btn_text:"ダウンロードしたファイルがどこにあるかわかりません",
        // btn_url:"https://help.piascore.com/hc/ja/articles/360038301052"
      })
    }

    // ダウンロード後
    if ( DEBUG || 
      (
        (msg.match(/ファイル|ダウンロード|dl|入っているか/i) && msg.match(/見つか|ません|できませ|出来ませ|できな|出来な|無くな|なくな/i)) 
        || (msg.match(/ダウンロード/i) && msg.match(/回数|失敗|エラー|見れな/i) && !msg.match(/印刷/i)) 
        || (msg.match(/閉じ/i) && msg.match(/見れな/i))
      ) ) {
      cautions.push({
        title:"楽譜がダウンロードできない",
        msg:<>ダウンロードした際に、メールにて楽譜データをお送りしておりますので、そちらをお使い下さい。詳しくは、<a target="_blank" href="https://help.piascore.com/hc/ja/articles/360038301052">ダウンロードしたファイルがどこにあるかわかりません</a>をご参照下さい。<br /><br />
          まだダウンロードしていない場合は、購入した楽譜のページにある「ダウンロード」を押して、ダウンロードして下さい。</>,
        btn_text:"ダウンロードしたファイルがどこにあるかわかりません",
        btn_url:"https://help.piascore.com/hc/ja/articles/360038301052"
      })
    }

    ////////////////////////////////////////////////////////////
    // 楽譜表示

    if ( DEBUG || (msg.match(/ファイル形式/i) && msg.match(/サポート/i))) {
      cautions.push({
        title:"楽譜を開くと「指定されたファイルのファイル形式はサポートしていません」と表示される",
        msg:<>ダウンロードした際に送信しておりますパスワードのメールに、同じ楽譜ファイル（PDF）を添付しておりますのでそちらをお使いいただけますでしょうか。</>,
        btn_text:null,
        btn_url:null
      })
    }

    if ( DEBUG || 
      (
        msg.match(/ダウンロード|ファイル|pdf|パスワード|pw/i) && msg.match(/楽譜|ファイル|購入|閲覧|見れ|開けま|誤り|困/i)
        || msg.match(/ロック/i) && msg.match(/解除/i)
      )) {
      cautions.push({
        title:"楽譜が開けない、または楽譜の閲覧パスワードがわからない",
        msg:<>楽譜が開けない、またはパスワードがわからない場合は、<a target="_blank" href="https://help.piascore.com/hc/ja/articles/360000842912">ダウンロード後のパスワードがわからなくなりました</a>をご確認ください</>,
        btn_text:"ダウンロード後のパスワードがわからなくなりました",
        btn_url:"https://help.piascore.com/hc/ja/articles/360000842912"
      })
    }

    ////////////////////////////////////////////////////////////
    // 印刷（コンビニ）

    if ( DEBUG || msg.match(/展開失敗|G006|printsmash/i)) {
      cautions.push({
        title:"コンビニでの印刷時に「展開失敗」「G006」などのエラーが出る",
        msg:<>PrintSmash から印刷をするなど、正しい印刷設定がされていない可能性がございます。下記をお読みの上、正しい印刷設定を行ってください。</>,
        btn_text:"ローソン、ファミリーマート、ポプラグループでのコンビニ印刷方法",
        btn_url:"https://help.piascore.com/hc/ja/articles/360054730352"
      })
    }
    
    if ( DEBUG || (msg.match(/印刷|プリント/i) && msg.match(/期限/i)) ) {
      cautions.push({
        title:"コンビニで印刷期限を過ぎてしまった",
        msg:
          <>
            お急ぎの場合は、ご自身で<a target="_blank" href="https://help.piascore.com/hc/ja/articles/360054730352">ローソン、ファミリーマートでのコンビニ印刷</a>の手続きを行っていただくか、再度コンビニ印刷をお申し込みください,
          </>,
        btn_text:"ローソン、ファミリーマートでのコンビニ印刷",
        btn_url:"https://help.piascore.com/hc/ja/articles/360054730352"
      })
    }

    if ( DEBUG || 
      (
        ((msg.match(/印刷|プリント/i) && msg.match(/コンビニ|セブン|メール/i)) || msg.match(/番号|ナンバー/i)) && msg.match(/送られ|届きま|届か|届いていない|届いていません|来ませ|きませ/i)) 
        || msg.match(/グレー|申込中|押せない/i)
      ) {
      //if ( DEBUG || (msg.match(/コンビニ|セブン/i) && msg.match(/届か/i))) {
      cautions.push({
        title:"コンビニ印刷（セブンイレブンのネットプリント）を申し込んだが、メールが届かない",
        //title:"コンビニ印刷（セブンイレブン）をお申し込みの方へ",
        msg:<>セブンイレブンのコンビニ印刷をお申し込みの方の方は、ネットプリントの登録まで<span style={{"fontWeight":"bold"}}>2営業日</span>頂く場合がございます。その間、コンビニ印刷ボタンはグレー色に、再度押すことができなくなります。詳しくは<a target="_blank" href="https://help.piascore.com/hc/ja/articles/360057115231">コンビニ印刷を依頼したのに届きません</a>をご覧ください。<br /><br />お急ぎの方は、ローソン、ファミリーマート、ポプラグループのコンビニで、手動にて登録・印刷頂けますのでご利用下さい。方法は、<a target="_blank" href="https://help.piascore.com/hc/ja/articles/360054730352">購入した楽譜をコンビニで印刷したい（ローソン、ファミリーマート、ポプラグループなどネットワークプリント）</a>をご覧ください。</>,
        // btn_text:"ローソン、ファミリーマートでのコンビニ印刷",
        // btn_url:"https://help.piascore.com/hc/ja/articles/360054730352"
      })
    }

    if ( DEBUG || msg.match(/ローソン|ファミリーマート|ファミマ|ユーザ番号|ユーザー番号/i)) {
      cautions.push({
        title:"ローソン・ファミリーマートでのコンビニ印刷について",
        msg:<>うまく登録できない方は<a target="_blank" href="https://help.piascore.com/hc/ja/articles/360054730352">ローソン、ファミリーマート、ポプラグループでのコンビニ印刷方法</a>をご覧ください。また登録には、必ずWeb版の<a target="_blank" href="https://networkprint.ne.jp/Lite/start?lang=jajp">ネットワークプリンサービス</a>をご利用下さい。アプリからですと、うまく登録できない場合がございます。</>,
        btn_text:"ローソン、ファミリーマートでのコンビニ印刷",
        btn_url:"https://help.piascore.com/hc/ja/articles/360054730352"
      })
    }

    if ( DEBUG
      || (msg.match(/コンビニ|セブン/i) && msg.match(/送って|印刷したい|プリントしたい|印刷できます|プリントできます/i)) 
      || msg.match(/コンビニ印刷/i)
    ) {
      cautions.push({
        title:"コンビニで印刷したい",
        msg:<><span style={{"fontWeight":"bold"}}><a target="_blank" href="https://www.printing.ne.jp/index_p.html">ネットプリント（セブンイレブン）</a>の場合</span><br />パスワード付きの楽譜ファイルを登録・印刷することはできません。コンビニ印刷のお申込が必要です。詳しくは<a target="_blank" href="https://help.piascore.com/hc/ja/articles/360038987412">コンビニ印刷とはなんですか？</a>をご覧ください。<br /><br /><span style={{"fontWeight":"bold"}}><a target="_blank" href="https://networkprint.ne.jp/sharp_netprint/ja/top.aspx">ネットワークプリント（ローソン、ファミリーマート）</a>の場合</span><br />ご購入者様の方でコンビニ印刷の登録ができます。「方法がわからない」「認証失敗などうまく行かない」などの場合は、<a target="_blank" href="https://help.piascore.com/hc/ja/articles/360038987412">コンビニ印刷とはなんですか？</a>をご覧ください。</>,
        btn_text:"コンビニ印刷とはなんですか？",
        btn_url:"https://help.piascore.com/hc/ja/articles/360038987412"
      })
    }

    if ( DEBUG || ((msg.match(/印刷|プリント|印刷を/i) && msg.match(/できない|できません|出来ません|出来ない/i)) || (msg.match(/スマホ/i) && msg.match(/印刷/i)) ) ) {
      if ( DEBUG || msg.match(/コンビニ|セブン|ローソン/i)) {
        cautions.push({
          title:"コンビニで印刷できない",
          msg:<>下記のリンクをご覧いただき、コンビニ印刷をお申し込み下さい。</>,
          btn_text:"コンビニ印刷とはなんですか？",
          btn_url:"https://help.piascore.com/hc/ja/articles/360038987412"
        })
      }else {
        cautions.push({
          title:"PCやスマホから印刷できない",
          msg:<>購入した楽譜が印刷できない場合は、<a target="_blank" href="https://help.piascore.com/hc/ja/articles/360044110211">購入した楽譜が印刷できません</a>をご確認ください</>,
          btn_text:"購入した楽譜が印刷できません",
          btn_url:"https://help.piascore.com/hc/ja/articles/360044110211"
        })
      }
    }

    if ( DEBUG || msg.match(/パスワード|pw/i)) {
      if ( DEBUG || msg.match(/コンビニ|セブン|ローソン/i)) {
        cautions.push({
          title:"コンビニで印刷できない方へ",
          msg:<><span style={{"fontWeight":"bold"}}><a target="_blank" href="https://www.printing.ne.jp/index_p.html">ネットプリント（セブンイレブン）</a>の場合</span><br />パスワード付きの楽譜ファイルを登録・印刷することはできません。コンビニ印刷のお申込が必要です。詳しくは<a target="_blank" href="https://help.piascore.com/hc/ja/articles/360038987412">コンビニ印刷とはなんですか？</a>をご覧ください。<br /><br /><span style={{"fontWeight":"bold"}}><a target="_blank" href="https://networkprint.ne.jp/sharp_netprint/ja/top.aspx">ネットワークプリント（ローソン、ファミリーマート）</a>の場合</span><br />ご購入者様の方でコンビニ印刷の登録ができます。「方法がわからない」「認証失敗などうまく行かない」などの場合は、<a target="_blank" href="https://help.piascore.com/hc/ja/articles/360038987412">コンビニ印刷とはなんですか？</a>をご覧ください。</>,
          btn_text:"コンビニ印刷とはなんですか？",
          btn_url:"https://help.piascore.com/hc/ja/articles/360038987412"
        })
      }else{
        if ( DEBUG || msg.match(/印刷|プリント|プリンター/i)) {
          cautions.push({
            title:"自宅などのプリンターで印刷する際のパスワードをがわからない",
            msg:<>パスワードは、閲覧時に必要なパスワードをご入力下さい。パスワードがわからない場合は、<a target="_blank" href="https://help.piascore.com/hc/ja/articles/360000842912">ダウンロード後のパスワードがわからなくなりました</a>をご確認ください</>,
            btn_text:"ダウンロード後のパスワードがわからなくなりました",
            btn_url:"https://help.piascore.com/hc/ja/articles/360000842912"
          })
        }
      }
    }

    // 印刷時の課金
    if ( DEBUG || (msg.match(/コンビニ|セブン|ローソン/i) && msg.match(/印刷|プリント/i) && msg.match(/料金|金額/i)) ) {
      cautions.push({
        title:"コンビニで印刷する際に、再度支払いが必要ですか？",
        msg:<>下記のリンクをご参照ください。</>,
        btn_text:"コンビニで印刷する際に、再度支払いが必要ですか？",
        btn_url:"https://help.piascore.com/hc/ja/articles/42140091314841"
      })
    }

    ////////////////////////////////////////////////////////////
    // 印刷（自宅）

    //if ( DEBUG || (!msg.match(/コンビニ|セブン|ローソン|ファミマ/i) && msg.match(/プリント|印刷/i) && msg.match(/パソコン|自宅|PC/i)) ) {
    if ( DEBUG || (msg.match(/プリント|印刷|プリンタ/i) && msg.match(/パソコン|自宅|PC/i)) ) {
      cautions.push({
        title:"自宅で印刷するには",
        msg:<>ご自宅で印刷したい場合は、<a target="_blank" href="https://help.piascore.com/hc/ja/articles/360038881451">購入した楽譜を印刷することはできますか？</a>をご確認ください</>,
        btn_text:"購入した楽譜を印刷することはできますか？",
        btn_url:"https://help.piascore.com/hc/ja/articles/360038881451"
      })
    }

    if ( DEBUG || (!msg.match(/コンビニ|セブン|ローソン|ファミマ/i) && msg.match(/プリント|印刷/i) && msg.match(/権限|中止|中断|できない|出来ない|エラー/i)) ) {
      cautions.push({
        title:"自宅などのプリンターで印刷できない",
        //title:"「プリントする権限がありません」と表示される",
        msg:<>購入した楽譜が印刷できない場合は、<a target="_blank" href="https://help.piascore.com/hc/ja/articles/360044110211">購入した楽譜が印刷できません</a>をご確認ください</>,
        btn_text:"購入した楽譜が印刷できません",
        btn_url:"https://help.piascore.com/hc/ja/articles/360044110211"
      })
    }

    if ( DEBUG || msg.match(/air print|airprint|エアープリント|epson|エプソン|スマホから印刷|スマホからの印刷/i)) {
      cautions.push({
        title:"エプソン（EPSON）のプリンタをお使いの方へ",
        msg:<>スマホから楽譜の印刷ができない場合は、<a target="_blank" href="https://help.piascore.com/hc/ja/articles/360044110211">購入した楽譜が印刷できません</a>をご参照下さい。</>,
        btn_text:"購入した楽譜が印刷できません",
        btn_url:"https://help.piascore.com/hc/ja/articles/360044110211"
      })
    }

    ////////////////////////////////////////////////////////////
    // 印刷（一般）
    
    if ( DEBUG || (cautions.length == 0 && msg.match(/プリント|印刷|プリンタ/i)) ){
      cautions.push({
        title:"印刷できない",
        msg:<>自宅、またはコンビニで印刷できない場合は、<a target="_blank" href="https://help.piascore.com/hc/ja/articles/360038881451">購入した楽譜を印刷することはできますか？</a>をご参照下さい。</>,
        btn_text:"購入した楽譜を印刷することはできますか？",
        btn_url:"https://help.piascore.com/hc/ja/articles/360038881451"
      })
    }
    
    
    ////////////////////////////////////////////////////////////
    // サポート

    if ( DEBUG || (msg.match(/製本|紙の|紙楽譜|郵送|封書/i) && !msg.match(/表紙/i)) ) {
      cautions.push({
        title:"製本版・紙の楽譜について",
        msg:<>当サービスはダウンロード型の電子楽譜の販売サイトとなります。申し訳ございませんが、製本版・紙の楽譜での郵送などについては一切行っておりません。コンビニでの印刷をご検討下さい。</>,
        btn_text:"購入した楽譜をコンビニで印刷できますか？",
        btn_url:"https://help.piascore.com/hc/ja/articles/360038388632"
      })
    }

    if ( DEBUG || msg.match(/電話/i)) {
      cautions.push({
        title:"お電話での対応について",
        msg:<>申し訳ございませんが、お電話での対応については行っておりません。<br /><br />こちらのフォームよりご連絡下さい。</>,
        btn_text:null,
        btn_url:null
      })
    }
    
    if ( DEBUG || msg.match(/iPad|取り込む|アプリ|airturn|air turn|irig|blueturn|blue turn|blue board|ジェスチャー|ウィンク|同期|電子楽譜ビューア|アルバム|セットリスト/i)) {
      cautions.push({
        title:"アプリについてのお問い合わせ",
        msg:<>こちらは、Piascore楽譜ストアに関するお問い合わせのみ受け付けております。<br /><br />iPad/iPhone用アプリ <a target="_blank" href="https://apps.apple.com/jp/app/piascore-hd/id406141702">Piascore</a> のお問い合わせについては、アプリ内の設定 > 「ヘルプ」 > 「メール」よりお問い合わせ下さい。</>,
        btn_text:null,
        btn_url:null
      })
    }

    
    if ( DEBUG || msg.match(/会費/i)) {
      cautions.push({
        title:"会費はかかりますか？",
        msg:<>2024年6月現在、会費はかかりません。楽譜をご購入毎にお支払が必要となります。</>,
        btn_text:null,
        btn_url:null
      })
    }
    
    
    ////////////////////////////////////////////////////////////
    // 退会

    if ( DEBUG || (msg.match(/退会/i) )) {
      cautions.push({
        title:"退会したい",
        msg:<>下記をご参照下さい。</>,
        btn_text:"退会方法を教えてください",
        btn_url:"https://help.piascore.com/hc/ja/articles/360038685231"
      })
    }
    
    if ( DEBUG || (msg.match(/クレジット|クレカ|カード情報/i) && msg.match(/解除|破棄|削除/i))) {
      cautions.push({
        title:"クレジットカード情報の破棄について",
        msg:<>下記をご参照下さい。</>,
        btn_text:"退会後、クレジットカードの情報は削除されますか？",
        btn_url:"https://help.piascore.com/hc/ja/articles/360038685511"
      })
    }

    ////////////////////////////////////////////////////////////
    // ぴあ
    
    if ( DEBUG || (msg.match(/チケット/i))) {
      cautions.push({
        title:"チケットぴあ様へのお問い合わせについて",
        msg:<>こちらは、<a target="_blank" href="https://t.pia.jp/">チケットぴあ</a>ではございません。チケットなどお問い合わせは、下記の「チケットぴあのヘルプ」をご参照下さい。</>,
        btn_text:"チケットぴあのヘルプ",
        btn_url:"https://t.pia.jp/help/index.jsp"
      })
    }

    ////////////////////////////////////////////////////////////
    // 営業系
    
    if ( DEBUG || (msg.match(/株式会社|弊社/i) && msg.match(/実績|紹介|制作|営業|返信|候補/i))) {
      cautions.push({
        title:"営業コンタクトのお断り",
        msg:<>こちらはお客様専用のフォームです。弊社の断りのない営業コンタクトは、一切お断りしております。ご了承ください。</>,
        btn_text:null,
        btn_url:null,
        disableSubmit:true
      })
    }

    ////////////////////////////////////////////////////////////
    // その他

    // パスワード全般

    if ( DEBUG || (cautions.length == 0 && msg.match(/パスワード|pw/i)) ) {
      cautions.push({
        title:"パスワードがわからない",
        msg:<>
        下記のいずれのケースかご確認ください。<br /><br />
          <div style={{"fontWeight":"bold", "margin":"0px 0 0 0"}}>A. ログインのためのパスワードが分からない</div>
          <div>会員登録がお済みの場合、<a target="_blank" href="/request_password_reset">パスワードリセット</a>より、パスワードの再設定を行って下さい。<br /><br />会員登録がお済でない場合、会員登録が完了していない場合がございます。「[Piascore] 仮登録のお知らせ」というタイトルのメール内にあるリンクをクリックし、会員登録を完了して下さい。<br /><br />届いていない場合は、本フォームよりご連絡下さい。</div>

          <div style={{"fontWeight":"bold", "margin":"40px 0 0 0"}}>B. 楽譜を開くためのパスワードが分からない</div>
          <div>楽譜が開けない、またはパスワードがわからない場合は、<a target="_blank" href="https://help.piascore.com/hc/ja/articles/360000842912">ダウンロード後のパスワードがわからなくなりました</a>をご確認ください</div>

          <div style={{"fontWeight":"bold", "margin":"40px 0 0 0"}}>C. 印刷時に必要なパスワードが分からない</div>
          <div>パスワードは、閲覧時に必要なパスワードをご入力下さい。パスワードがわからない場合は、<a target="_blank" href="https://help.piascore.com/hc/ja/articles/360000842912">ダウンロード後のパスワードがわからなくなりました</a>をご確認ください</div>
        </>,
        btn_text:null,
        btn_url:null,
      })
    }
    
    
    return cautions
    //return [cautionHTML, disableSubmit];
  }

  useEffect(() => {
    if(isPayByBank) {
      onChangeScores(scores)
    }
  }, [isPayByBank])

  useEffect(() => {
    setCautionHTML("")
    setShowSubmitAlert(false)
  }, [opinionTypeState])

  const onChangeOpinionType = (e) => {
    const opinionType     = e.target.value; //document.getElementById("opinionType").value;

    const callback = () => {
      setOpinionTypeState(opinionType)
      //this.setState({opinionType:opinionType})
      showMessage(opinionType);
      
      setIsPayByBank(opinionType == "pay_by_bank")
    }
    
    if(message) {
      if (window.confirm('お問い合わせ種別を変更すると、「内容」が消去されます。よろしいですか？')) {
        callback()
      }else{
      }
    }else{
      callback()
    }
    
  }
  
  const showMessage = (opinionType) => {    
    
    let jsx_msg = "";
    let message = "";

    switch (opinionType) {
      case "download":
        jsx_msg = <>ダウンロード時にお送りしたパスワードメールに、ファイルが添付されている可能性がございます。<br /><br />一度、メールボックスご確認ください。</>
        break;
      case "cvs_print":
        jsx_msg = <>コンビニ印刷が必要な方は、<a target="_blank" href="https://help.piascore.com/hc/ja/articles/360038987412">コンビニ印刷の手続き</a> をご参照ください</>
        break;
      case "publish_receipt":
        jsx_msg = <>領収書が必要な方は、<a target="_blank" href="https://help.piascore.com/hc/ja/articles/360038933411-%E9%A0%98%E5%8F%8E%E6%9B%B8%E3%81%AE%E7%99%BA%E8%A1%8C%E3%81%AF%E3%81%A7%E3%81%8D%E3%81%BE%E3%81%99%E3%81%8B-">領収書の発行手順</a> をご参照ください</>
        break;
      case "unsubscribe":
        setDisableSubmit(true)
        jsx_msg = <>クレジットカード以外の決済をご希望ですか？<br /><br />クレジットカードをお持ちでない、あるいはクレジットカードでのご購入に不安がある方は、銀行振込によるお支払いに対応いたします。<a style={{textDecoration:"underline"}} href="/contact?opinion_type=pay_by_bank">こちら</a>よりお申し込みください。<br /><br />退会されたい方は、下記をご参照ください。<br /><a style={{textDecoration:"underline"}} target="_blank" href="https://help.piascore.com/hc/ja/articles/360038685231">退会方法を教えてください</a></>
        break;
      case "pay_by_bank":
        // jsx_msg = <>下記の「内容」欄に、購入希望楽譜の情報をお書きください。お振込方法をお知らせいたします。楽譜IDについては、<a target="_blank" href="https://help.piascore.com/hc/ja/articles/4407712683289">銀行振り込みで購入したい</a>をご覧ください。<div style={{"margin":"10px 20px"}}>【例】<br /><div style={{"padding":"0 0 0 10px"}}>1. 楽譜名：インベンション<br />2. 楽譜ID：3056<br /></div></div>一度に複数の楽譜をお求めの場合は、「1. 楽譜名」「2. 楽譜ID」を複数お書き下さい。</>
        // message = "1. 楽譜名： \n2. 楽譜ID： ";
        if(isLogined) {
          jsx_msg = <>下記に、購入希望楽譜の情報（楽譜ID）を入力してください。お振込方法をお知らせいたします。楽譜IDについては、<a target="_blank" href="https://help.piascore.com/hc/ja/articles/4407712683289">銀行振り込みで購入したい <FontAwesomeIcon icon={faArrowUpRightFromSquare} /> </a>をご覧ください。<br /><br />銀行振込につきましては、購入合計額（税込）が<span style={{fontWeight:"bold"}}>1,000円以上</span>の場合に限り対応させていただきます。<br /><br />コンビニ印刷は、ダウンロードが可能になりました後に、各楽譜のページより手続きを行ってください。<br /><a target="_blank" href="https://help.piascore.com/hc/ja/articles/360038388632">購入した楽譜をコンビニで印刷できますか？ <FontAwesomeIcon icon={faArrowUpRightFromSquare} /> </a></>
        }
        message = "";
        break;
      case "wrong_score":
        jsx_msg = <>楽譜販売者にお伝えするにあたり、詳細情報が必要です。お手数ですが、下記の「内容」欄に、楽譜・箇所・不具合を詳しくお書きください。<br />
            <div style={{"margin":"10px 20px"}}>【例】<br /><div style={{"padding":"0 0 0 10px"}}>1. 楽譜名：インベンション<br />2. 楽譜URL：https://store.piascore.com/scores/1<br />3. 箇所：2小節目の3つ目の音<br />4. 不具合の内容：A音になっているが、正しくはB音である</div></div></>
        message = "1. 楽譜名： \n2. 楽譜URL： \n3. 箇所： \n4. 不具合の内容：";
        break;
    }

    // const old = document.getElementById("message").value;
    // if(old!=null && old != "") {
    //   message = old + "\n" + message;
    // } 
    
    setHTMLMsg(jsx_msg)
    setMessage(message)
    //this.setState({ html_msg: jsx_msg, message:message });
  }
  
  const handgeChechShowAlert = (e) => {
    //if(e.target.checked) {
      
      setShowSubmitAlert(false)
      setDisableSubmit(false)
    //}
  }
  
  const [scores, setScores] = useState([])
  const onChangeScores = (scores) => {    
    setScores(scores)

    const priceSum = scores.reduce((acc, score) => {
      return acc + score.distribute.jpy_price_with_tax
    }, 0)
    
    if(priceSum >= 1000) {
      let msg = "";
      scores.map((score) => {
        const row = [score.regions.ja.name, score.distribute.jpy_price_with_tax, `https://store.piascore.com/scores/${score.score_id}`, score.score_id].join(",")
        msg = msg + row + "\n"
      })
      
      setMessage(msg)
      setDisableSubmit(false)
    }else{
      setDisableSubmit(true)      
    }
  }
  
  return (
    <>
      {/*
      <Modal show={this.state.showModal} onHide={this.closeModal} centered>
        <Modal.Body id="modal_msg">{this.state.modal_message}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.closeModal}>
            閉じる
          </Button>
        </Modal.Footer>
      </Modal>
      */}
      <Form className={styles.contact_body} style={{fontSize:"16px"}} action="/send_opinion" acceptCharset="UTF-8" method="post" onSubmit={ handleOnSubmit }>

        <Row className={`align-items-center ${styles.row}`}>
          <Form.Label column="sm" lg={3}>お名前</Form.Label>
          <Col><Form.Control className={`rounded-4`} name="name" id="name" size type="text" placeholder="ピアスコア太郎" required /></Col>
        </Row>

        <Row className={`align-items-center ${styles.row}`}>
          <Form.Label column="sm" lg={3}>メールアドレス</Form.Label>
          <Col><Form.Control className={`rounded-4`} name="mail_address" id="mail_address" size type="email" placeholder="sample@piascore.com" required /></Col>
        </Row>

        <Row className={`align-items-center ${styles.row}`}>
          <Form.Label column="sm" lg={3}>お問い合わせ種別</Form.Label>
          <Col>
            <Form.Select className={`rounded-4`} style={{"height":"43px"}} value={opinionTypeState} name="opinion_type" id="opinion_type" aria-label="" onChange={onChangeOpinionType } required>
              <option value="none">--- 選択してください ----</option>
              <option value="download">楽譜の購入・ダウンロード</option>
              <option value="regist_user">会員登録</option>

              { isLogined && <option value="cvs_print">コンビニ印刷</option> }
              { isLogined && <option value="publish_receipt">領収書の発行</option> }
              { isLogined && <option value="unsubscribe">退会申請</option> }
              { isLogined && <option value="pay_by_bank">銀行振込による購入</option> }
              { isLogined && <option value="wrong_score">楽譜上の誤り・不足</option> }

              <option value="claim_rights">権利侵害曲の申し立て</option>
              <option value="improve_service">サービスへのご意見・ご要望 </option>
              <option value="other">その他</option>
            </Form.Select>
          </Col>
        </Row>

        <div style={{display:(opinionTypeState!="none" ? "block" : "none")}}>
          <Row className={`align-items-center ${styles.row}`}>
            <Form.Label column="sm" lg={3}></Form.Label>
            <Col>
              {(htmlMsg != "") &&
                <div className={`alert alert-info`} role="alert">
                  <span id="msg" style={{/*"color":"#cf431e",*/ "fontSize":"14px"}}>{htmlMsg}</span>
                </div>
              }
            </Col>
          </Row>

          <Row style={{display:(isPayByBank ? "flex" : "none")}} className={`align-items-center ${styles.row}`}>
            <Col column="sm" lg={3}>
            </Col>
            <Col>
              {
                isLogined ?
                (
                  <PSStoreContactScoreInputComponent apiBaseURL={apiBaseURL} onChangeScores={onChangeScores} />
                )
                :
                (
                  <Alert variant="primary">
                    <div style={{fontWeight:"bold"}}>銀行振込による購入には、ログインが必要です</div>
                    <div>ログイン後、再度お試しください。</div>
                    <div><a style={{margin:"10px 0 0 0", padding:"10px 20px", textDecoration:"none"}} href="/login" className="btn btn-primary">ログインページへ</a></div>
                  </Alert>
                )
              }

            </Col>
          </Row>
          
          <Row style={{display:(isPayByBank ? "none" : "flex")}} className={styles.row}>
          {/* <Row className={styles.row}> */}
            <Form.Label column="sm" lg={3}>内容</Form.Label>
            <Col className={styles.col}>
              <Form.Control className={`rounded-4`} name="message" id="message" as="textarea" rows={5} value={message} placeholder="内容をお書き下さい" onChange={e => setMessage(e.target.value)} onBlur={handleChange} /*onBlur={ (e) => { const msg=handleChange(e); setState({message:msg}) }}*/ required />
            </Col>
          </Row>

          {
            cautionHTML ? 
            (
              <Row style={{display:(isPayByBank ? "none" : "flex")}} className={styles.row}>
                <Col column="sm" lg={3}></Col>
                <Col className={styles.col}>
                  <Alert variant="success">
                    <div style={{fontWeight:"bold"}}>下記のヘルプをご確認ください</div>
                  </Alert>
                  
                  {cautionHTML}
                </Col>
              </Row>
            )
            :
            ""
          }
           
          {/* <Row style={{marginTop:20}} className={styles.row}>
            <Col column="sm" lg={3}></Col>
            <Col>
              <ReCAPTCHA style={{background:"#FFFFFF"}} sitekey={ process.env.PIASCORE_STORE_RECAPTCHA_CONTACT_SITE_KEY } onChange={onRecaptchaChange} />
            </Col>
          </Row> */}
        
          <Row style={{marginTop:20, display:(showSubmitAlert ? "flex" : "none"), fontSize:14}} className={styles.row}>
            <Col column="sm" lg={3}>
            </Col>
            <Col>
              <Alert variant="danger">
                <Row style={{alignItems:"center"}}>
                  <Col md={8}>
                    送信前に、上記のヘルプをご確認ください
                  </Col>
                  <Col md={4} style={{textAlign:"center"}}>
                    <Button variant="danger" style={{"height":"50px", "width":"100%", maxWidth:"100%"}} onClick={handgeChechShowAlert}>確認しました</Button>
                    {/* <input type="checkbox" checked={!showSubmitAlert} onChange={handgeChechShowAlert} /> */}
                  </Col>
                </Row>
              </Alert>
            </Col>
          </Row>
          
          <Row style={{marginTop:20}} className={`text-center button ${styles.row}`}>
            <Col>
              <Button disabled={disableSubmit /* || !recaptchaValue */} type="submit" style={{"height":"50px", "width":"300px", maxWidth:"100%"}} /*onClick={(e) => this.checkMessage(this.state.message)}*/ >送信する
              </Button>
            </Col>
          </Row>
        </div>
      </Form>
    </>
  )
}

//class PSStoreContactComponent extends React.Component {
export default function PSStoreContactComponent({isLogined, opinionType, apiBaseURL}) {

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.PIASCORE_STORE_RECAPTCHA_CONTACT_SITE_KEY}
      scriptProps={{
        async: true,
        defer: true,
        appendTo: 'head',
      }}
    >
      <div className={`${styles.contact_container} rounded-4`}>
        <FormHeader />
        <FormBody isLogined={isLogined} opinionType={opinionType} apiBaseURL={apiBaseURL} />
      </div>
    </GoogleReCaptchaProvider>
  )
  
  // return (
  //   <div className={`${styles.contact_container} rounded-4`}>
  //     <FormHeader />
  //     <FormBody isLogined={isLogined} opinionType={opinionType} apiBaseURL={apiBaseURL} />
  //   </div>
  // );
}

// ========================================

// const isLogined = true;
// //const isLogined = false;

// ReactDOM.render(
//   <PSStoreContactComponent isLogined={isLogined} />,
//   document.getElementById("app")
// );

//export default PSStoreContactComponent;