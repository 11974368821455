//
//  Simple URLSearchParams library

const PSURLSearchParams = {
  get: (key) => {
    const query = location.search;
    const params = new URLSearchParams(query);
    return params.get(key);
  }
}

export default PSURLSearchParams;
