import React, { useEffect, useState } from 'react';

import { Alert, Button, FloatingLabel, Form } from "react-bootstrap";
import styles from './PSStoreLoginComponent.module.scss'
import PSSession from '../common/PSSession';
import PSURLSearchParams from '../common/PSURLSearchParams';
import { router } from '../common/PSRouter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function LoginFailAlert() {
  return (
    <Alert variant="warning">
      <ul>
        <li style={{fontSize: 14, lineHeight: "1.8em"}}>
          ログインできません。以下の可能性がございます
          <div className="container" style={{fontSize: 14, lineHeight: "1.7em"}}>
            <div className="row mt-3">
              <div className="col fw-bold">メールアドレス/パスワードが間違っている</div>
            </div>
            <div className="row">
              <div className="col">
                <a href="/request_password_reset">パスワードのリセット</a>を行ってください
              </div>
            </div>
            <div className="row mt-4">
              <div className="col fw-bold">
                登録が完了していないなどの理由により無効なユーザ
              </div>
            </div>
            <div className="row">
              <div className="col">
                仮登録時に送られるメール内のリンクをクリックして登録を完了するか、<a href="/sign_up">ユーザー登録</a>より再度登録し直してください
              </div>
            </div>
          </div>
        </li>
      </ul>
    </Alert>
  );
}

export default function PSStoreLoginComponent({api_base_url, mail_address}) {
  const [mailAddress, setMailAddress] = useState("");
  const [password, setPassword] = useState("");
  const [loginFailed, setLoginFailed] = useState(false);

  const login = async (e) => {
    e.preventDefault();

    setLoginFailed(false);
    try {
      const success = await PSSession.login({mailAddress, password, apiBaseUrl: api_base_url});
      if (success) {
        router.push("/");
      } else {
        setLoginFailed(true);
      }
    } catch (err) {
      setLoginFailed(true);
    }
  }

  useEffect(() => {
    const mailAddress = PSURLSearchParams.get('mail_address');
    if (mailAddress) {
      setMailAddress(mailAddress);
    }
    if (mail_address) {
      setMailAddress(mail_address);
    }
  }, []);

  return (
    <>
      {loginFailed && <LoginFailAlert />}
      <div className={`${styles.auth_container} p-4 rounded-5`}>
        <div className="text-center">
          <h3 className="fw-bold mb-0 fs-4">ログイン</h3>
        </div>
        <Form className="mx-auto pt-4 fs-6" onSubmit={(e) => login(e)}>
          <FloatingLabel controlId="floatingInput" label="メールアドレス" className="text-muted">
            <Form.Control
              type="email"
              required
              placeholder="@"
              value={mailAddress}
              onChange={e => setMailAddress(e.target.value)}
              style={{marginBottom:-1,  borderBottomRightRadius:0, borderBottomLeftRadius:0}}
            />
          </FloatingLabel>

          <FloatingLabel controlId="floatingPassword" label="パスワード" className="text-muted">
            <Form.Control
              type="password"
              required
              placeholder="@"
              value={password}
              onChange={e => setPassword(e.target.value)}
              style={{marginBottom:10, borderTopRightRadius:0, borderTopLeftRadius:0}}
            />
          </FloatingLabel>

          <Form.Group className="text-center pb-3">
            <Button type="submit" variant="primary" size="lg" className={styles.login_button}>
              ログイン <span className="fa fa-arrow-circle-right ms-2"></span>
            </Button>
          </Form.Group>

          <Form.Group className="pt-3 text-center" style={{}}>
            <div>
              <a href="/request_password_reset">パスワードを忘れてしまった</a>
            </div>
            <div style={{marginTop: 30}}>
              <a href="https://publish.piascore.com/login">楽譜の販売者ですか？ログインはこちら</a>
            </div>
          </Form.Group>
        </Form>
      </div>
    </>
  )
}