import React, { useState, useEffect, createContext, useContext } from 'react';
import { Alert, Button, Form } from "react-bootstrap";
import PSSession from '../common/PSSession';
import PSFlush from '../common/PSFlush';
import { router } from '../common/PSRouter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleRight } from '@fortawesome/free-solid-svg-icons';
import styles from './PSStoreSignUpComponent.module.scss';

const SignupContext = createContext();

function SignUpTitle() {
  return (
    <div className={styles.progress_title}>会員情報の登録</div>
  );
}

function SignUpProgress({ status }) {
  return (
    <div className={styles.progress_img}>
      <img src={`/images/sign_up_progress/progress_0${status}.png`} />
    </div>
  );
}

function RequireIcon() {
  return <span className={styles.required}>必須</span>;
};

function LablePrompt({children}) {
  return <div><span className={styles.label_prompt}>{children}</span></div>
}

function ArrowLink({children, link}) {
  return (
    <a target="_blank" className={styles.arrow_link} href={link}>
      <span className='fa fa-arrow-right'></span>
      {children}
    </a>
  )
}

function SignupForm({api_base_url}) {
  const [userInfo, setUserInfo, setSignupState] = useContext(SignupContext);
  const [errorMessages, setErrorMessages] = useState([]);
  const [mailCorrectionMessage, setMailCorrectionMessage] = useState('');
  const [carrierMessage, setCarrierMessage] = useState(null);
  const [mailErrorMessage, setMailErrorMessage] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(!!userInfo.mailAddress);
  const [isValidPassword, setIsValidPassword] = useState(!!userInfo.password);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState('');
  
  useEffect(() => {
    // 値が変更される度にバリデーション
    if (userInfo.mailAddress) {
      setIsValidEmail(validateEmail(userInfo.mailAddress));
    }
    if (userInfo.password) {
      setIsValidPassword(validatePassword(userInfo.password));
    }
  }, [userInfo]); // コンポーネントマウント時に1回だけ実行

  const validateEmail = function(email) {
    if (!email) return false;
    
    // メールアドレスの基本的な形式チェック
    const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    return emailRegex.test(email);
  }

  const validateAndCorrectEmail = function(email) {
    if (!email || !email.includes('@')) {
      return {
        email: email || '',
        carrierIndex: -1
      };
    }
    
    let replaced_mail_address = email;

    // 全角 -> 半角変換
    replaced_mail_address = replaced_mail_address.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function(s) {
      return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
    });

    // 基本的な記号の修正
    replaced_mail_address = replaced_mail_address.replace(/＠/, "@");
    replaced_mail_address = replaced_mail_address.replace(/．|。|、/g, ".");
    replaced_mail_address = replaced_mail_address.toLowerCase();
    replaced_mail_address = replaced_mail_address.replace(/,/g, ".");
    replaced_mail_address = replaced_mail_address.replace(/\s+/g, '');

    const rs = replaced_mail_address.split("@");
    if (rs.length !== 2 || !rs[0] || !rs[1]) {
      return {
        email: email,
        carrierIndex: -1
      };
    }

    const [mail_name, mail_domain] = rs;

    // ドメインの修正
    let replaced_mail_domain = mail_domain;

    // 後方一致の修正
    replaced_mail_domain = replaced_mail_domain.replace(/\.do\.jp$|\.co\.jo$|\.co\.up$|\.c\.jp$|\.cojp$|\.conjp$|\.cp\.jp$|\.cojp$|\.o\.jp$|\.xo\.jp$/, ".co.jp");
    replaced_mail_domain = replaced_mail_domain.replace(/\.dom$|\.comk$|\.cm$|\.cod$|\.comp$|\.con$|\.cow$|\.co$|\.cpm$|\.aoo$|\.ccm$|\.cim$|\.cm$|\.co$|\.comn$|\.comm$|\.como$|\.comr$|\.comw$|\.coo$|\.coom$|\.cow$|\.cpm$|\.ocm$|\.om$|\.vom$|\.xom$|\.om$|\.cl$|\.co\.m$|\.cpm$|\.lcom$|\.co$|\.come$|\.comp$|\.coom$|\.cow$|\.cpm$|\.cpm$|\.vom$|\.co$|\.cop$/, ".com");
    replaced_mail_domain = replaced_mail_domain.replace(/\.j$|\.ip$|\.jg$|\.jo$|\.jpd$|\.jpka$|\.jpm$|\.jpof$|\.jpp$|\.jpq$|\.ip$|\.jg$|\.jpa$|\.jpb$|\.jpha$|\.jpm$|\.jpt$|\.ip$|\.jpk$|\.kp$|\.jg$|\.kp$|\.je$|\.jpe$|\.jpool$|\.kp$|\.ip$|\.jg$|\.jph$|\.jpp$|\.jpt$|\.jpu$|\.jpy$|\.kp$|\.p$/, ".jp");
    replaced_mail_domain = replaced_mail_domain.replace(/\.ne\.lp$|\.he\.jp$|\.ne\.p$|\.nejp$|\.n$|\.be\.jp$|\.jp\.ne$|\.ne$|\.no\.jp$|\.be\.jp$|\.me\.jp$|\.na\.jp$|\.nd\.jp$|\.ne$|\.ne\.com$|\.ne\.np$|\.ne\.p$|\.ne\.p$|\.nd\.jp$|\.me\.jp$|\.nejp$|\.ne\.com$|\.be\.jp$|\.je\.jp$|\.me\.jp$|\.nw\.jp$|\.n\.jp$/, ".ne.jp");
    replaced_mail_domain = replaced_mail_domain.replace(/\.orjp$/, ".or.jp");
    replaced_mail_domain = replaced_mail_domain.replace(/so-net\.jp$|so-net\.jp$/, "so-net.ne.jp");

    // 前方一致の修正
    replaced_mail_domain = replaced_mail_domain.replace(/^bocomo\.|^docmo\.|^dicomo\.|^docimo\.|^docino\.|^docom\.|^docomp\.|^docoomo\.|^dodomo\.|^donomo\.|^doomo\.|^focomo\./, "docomo.");
    replaced_mail_domain = replaced_mail_domain.replace(/^eweb\.|^exweb\.|^ez\.|^ezeeb\.|^ezeweb\.|^ezwea\.|^ezweaa\.|^ezwebe\.|^ezwed\.|^ezwerb\.|^ezwrb\.|^ezwweb\.|^ezxeb\./, "ezweb.");
    replaced_mail_domain = replaced_mail_domain.replace(/^gmmail\.|^gmal\.|^gmaili\.|^gmaail\.|^gmale\.|^bmail\.|^g-mail\.|^gail\.|^gamail\.|^gamil\.|^gmagl\.|^gmai\.|^gmaii\.|^gmaiI\.|^gmaiil\.|^gmai\.|^gmaij\.|^gmsil\.|^gmaile\.|^gmaill\.|^gmal\.|^gmali\.|^gmall\.|^gmaol\.|^gmeil\.|^gmel\.|^gmial\.|^gmil\.|^gmile\.|^gnail\.|^google\.|^gwail,/, "gmail.");
    replaced_mail_domain = replaced_mail_domain.replace(/^hoamail\.|^homail\.|^hotmai\.|^hotmaio\.|^hotmial\.|^hoymail,/, "hotmail.");
    replaced_mail_domain = replaced_mail_domain.replace(/^ioloud\.|^iclotd\.|^icliud\.|^ciloud\.|^cloud\.|^clud\.|^i\.cloud\.|^ibloud\.|^iccloud\.|^ichoud\.|^icioud\.|^icioud\.|^iclcud\.|^iclloud\.|^iclnud\.|^iclod\.|^iclond\.|^iclooud\.|^iclou\.|^icloub\.|^icloudo\.|^iclould\.|^iclowd\.|^iclud\.|^iclude\.|^icluod\.|^icluoud\.|^icolud\.|^icoud\.|^icould\.|^icroud\.|^iloud\.|^incloud\.|^ivloud\.|^ocloud\.|^ucloud\./, "icloud.");
    replaced_mail_domain = replaced_mail_domain.replace(/^autiook\.|^autlook\.|^outloo\.|^outlooku\.|^outolook\.|^outook\./, "outlook.");
    replaced_mail_domain = replaced_mail_domain.replace(/^yshoo\.|^ahoo\.|^tahoo\.|^uahoo\.|^yafoo\.|^yah\.|^yah00\.|^yahho\.|^yahhoo\.|^yaho\.|^yahoio\.|^yahooo\.|^yahooyahoo\.|^yahpp\.|^yaoo\.|^yhaoo\.|^yhaoo\.|^yhoo\./, "yahoo.");
    replaced_mail_domain = replaced_mail_domain.replace(/^ymobil\.|^ymibile\.|^ymodile\./, "ymobile.");

    // 部分一致の修正
    replaced_mail_domain = replaced_mail_domain.replace(/sftbank\.|siftbank\.|sntbank\.|sofbank\.|softbabk\.|softbak\.|softbamk\.|softbanki\.|softbanku\.|softbannk\.|softbnk\.|softobank\.|sotbank\.|softbanku\.|softobank\.|spftbank\.|softbnk\.|softban\./, "softbank.");

    // 完全一致の修正
    const domainCorrections = {
      '^au\.co\.com$|^au\.jp$|^i\.au\.com$': 'au.com',
      '^docomo\.com\.jp|^docono\.ne\.jp$|^docomo\.co\.jp$|^dokomo\.ne\.jp|^docomone\.jp|^docomo\.com$|^docomo\.jp$|^docomo\.ne\.co\.jp$|^docomo\.ne\.jpsaki$|^i\.docomo\.jp$': 'docomo.ne.jp',
      '^ezweb\.com$|^eaweb\.ne\.jp$|^edzweb\.ne\.jp$|^eweb\.jp$|^ezeweb\.jp$|^ezweb\.co\.jp$|^ezweb\.jp$|^ezweb\.ne\.co\.jp$|^ezweb\.ne\.jp\.ne\.jp$|^ezweb\.ne\.jpcake$|^ezweb\.ne\.jpMasa$|^ezweb\.ne\.jpsing$': 'ezweb.ne.jp',
      '^gmail\.com\.jp$|^371gmail\.com$|^coin\.gmall\.com$|^email\.com$|^gmail\.ac\.jp$|^gmail\.cc$|^gmail\.co\.jp$|^gmail\.com\.tw$|^gmail\.coma$|^gmail\.comGoogle$|^gmail\.jp$|^gmail\.ne\.jp$': 'gmail.com',
      '^hotmai\.co\.jp$|^hotmail\.com\.jp$': 'hotmail.co.jp',
      '^hotmail\.co\.th$': 'hotmail.com',
      '^i\.phone\.jp$|^i\.soft$|^i\.softbank$|^i\.Softbank$|^i\.softbank\.co\.jp$|^i\.softbank\.com$|^i\.softbankjp$': 'i.softbank.jp',
      '^17623icloud\.com$|^icloud\.c$|^i\.icloud\.com$|^icloud\.co\.jp$|^icloud\.com\.ne\.jp$|^icloud\.jp$|^icloud\.ne\.jp$': 'icloud.com',
      '^0509outlook\.jp$|^8outlook\.jp$|^outlook\.ne\.jp$': 'outlook.jp',
      '^g\.softbank\.co\.jp$|^softbank\.co\.jp$|^softbank\.go\.jp$': 'softbank.ne.jp',
      '^i\.softbank\.ne\.jp$|^isoftbank\.jp$|^softbank\.jp$': 'i.softbank.jp',
      '^36yahoo\.co\.jp$|^yahoo\.ac\.jp$|^yahoo\.com\.jp$|^yahoo\.jp$|^yahoo\.tnc\.co\.jp$|^yahooco\.jp$|^yahoowco\.jp$': 'yahoo.co.jp',
      '^com\.yahoo$': 'yahoo.com',
      '^yahoo\.ne\.ac\.jp$': 'yahoo.ne.jp',
      '^ymobile\.co\.jp$|^ymobile\.jp$|^ymobilene\.jp$': 'ymobile.ne.jp'
    };

    for (const [pattern, correction] of Object.entries(domainCorrections)) {
      const regex = new RegExp(pattern);
      if (regex.test(replaced_mail_domain)) {
        replaced_mail_domain = correction;
      }
    }

    // 最終的な修正済みメールアドレスを作成
    const finalEmail = `${mail_name}@${replaced_mail_domain}`;

    // Piascoreドメインのチェック
    if (replaced_mail_domain.match(/piascore/)) {
      return {
        email: email,
        carrierIndex: -1
      };
    }

    // 携帯キャリアのチェック
    const mobileAddresses = [
      ["docomo.ne.jp"],
      ["ezweb.ne.jp", "au.com"],
      ["i.softbank.jp", "softbank.ne.jp"]
    ];

    let carrierIndex = -1;
    for (let i = 0; i < mobileAddresses.length; i++) {
      const addresses = mobileAddresses[i];
      if (addresses.some(domain => replaced_mail_domain.endsWith(domain))) {
        carrierIndex = i;
        break;
      }
    }

    return {
      email: finalEmail,
      carrierIndex: carrierIndex
    };
  }

  const validatePassword = function(password) {
    const passwordRegex = /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])[a-zA-Z0-9._?!@#$%^&*\-=]{6,20}$/;
    return passwordRegex.test(password);
  }

  const handlePasswordChange = function(e) {
    const password = e.target.value;
    setUserInfo({ ...userInfo, password: password });
    setIsValidPassword(validatePassword(password));
    
    if(isValidPassword) {
      setPasswordErrorMessage('');
    } else {
      setPasswordErrorMessage('パスワードは6〜20文字、英大小・数字を最低一つずつ、「._?!@#$%^&*-=」が使用可能です');
    }
  }

  const handleEmailBlur = function(e) {
    const originalEmail = e.target.value.trim();
    
    // バリデーションチェック
    const isValid = validateEmail(originalEmail);
    setIsValidEmail(isValid);
    
    if (originalEmail && !isValid) {
      setMailErrorMessage('正しいメールアドレスの形式で入力してください');
      setMailCorrectionMessage('');
      setCarrierMessage(null);
      return;
    } else {
      setMailErrorMessage('');
    }

    const result = validateAndCorrectEmail(originalEmail);
    
    // 実際に修正が行われた場合のみメッセージを表示
    if (result.email !== originalEmail) {
      setMailCorrectionMessage('メールアドレスを自動修正しました');
      setTimeout(() => setMailCorrectionMessage(''), 3000);
      setUserInfo({ ...userInfo, mailAddress: result.email });
    } else {
      setMailCorrectionMessage('');
    }

    // 携帯キャリアメッセージの処理
    if (result.carrierIndex >= 0) {
      const carrierSettings = [
        { name: 'docomo', url: 'https://www.nttdocomo.co.jp/info/spam_mail/domain/' },
        { name: 'au', url: 'https://www.au.com/support/service/mobile/trouble/mail/email/filter/' },
        { name: 'Softbank', url: 'https://www.softbank.jp/mobile/support/antispam/settings/spammailfilter/' }
      ];
      
      const carrier = carrierSettings[result.carrierIndex];
      setCarrierMessage(
        <div className={styles.warning_message}>
          携帯アドレスをご利用の場合は、必ず事前に<span className={styles.domain_name}> piascore.com </span>
          からのメールを受信できるようにフィルターを設定して下さい<br />
          設定方法：<a href={carrier.url} target="_blank" rel="noopener noreferrer">{carrier.name}</a>
        </div>
      );
    } else {
      setCarrierMessage(null);
    }
  }
  
  // const handlePasswordBlur = function(e) {
  //   const password = e.target.value;
    
  //   setIsValidPassword(validatePassword(password));
  //   if(isValidPassword) {
  //     setPasswordErrorMessage('');
  //   } else {
  //     setPasswordErrorMessage('パスワードは6〜20文字、英大小・数字を最低一つずつ、「._?!@#$%^&*-=」が使用可能です');
  //   }
  // }

  const passwordPrompt = "6〜20文字、英大小・数字を最低一つずつ、「._?!@#$%^&*-=」が使用可能です";

  const isFormValid = function() {
    return (
      userInfo.mailAddress && 
      isValidEmail && 
      userInfo.password && 
      isValidPassword && 
      userInfo.termsOfService && 
      userInfo.privacyPolicy
    );
  }

  const clickConfirm = async (e) => {
    e.preventDefault();

    try {
      const registered = await PSSession.checkUserRegistered({mailAddress: userInfo.mailAddress, apiBaseUrl: api_base_url});
      if (registered) {
        setErrorMessages([
          <>
            メールアドレスはすでに存在します。
            <a href="/login">
              ログイン
            </a>
            してください
          </>
        ]);
      } else {
        setErrorMessages([]);
        setSignupState(1);
      }
    } catch (err) {
      setErrorMessages([<>エラーが発生しました。時間をおいて再度お試しいただくか、<a target="_blank" rel="noopener noreferrer" href="/contact">コンタクト</a>よりお問い合わせください。</>]);
    }
  }

  return (
    <div className={styles.signup_wrapper}>
      {errorMessages.length > 0 && (
        <Alert variant="warning">
          <ul>
            {errorMessages.map((message, ix) => (
              <li key={ix}>{message}</li>
            ))}
          </ul>
        </Alert>
      )}
      <div className={styles.progress_container}>
        <SignUpTitle />
        <SignUpProgress status={1} />
      </div>
      <div className={styles.signup_container}>
        <Form onSubmit={clickConfirm}>
          <Form.Group className={styles.form_group}>
            <Form.Label className={styles.label}>
              メールアドレス <RequireIcon />
            </Form.Label>
            <Form.Control
              type="email"
              required
              className={styles.input}
              placeholder="example@piascore.com"
              value={userInfo.mailAddress}
              onChange={(e) => {
                setUserInfo({ ...userInfo, mailAddress: e.target.value });
                setMailErrorMessage(''); // 入力時にエラーメッセージをクリア
              }}
              onBlur={handleEmailBlur}
            />
            {mailErrorMessage && (
              <div className={styles.error_message}>
                {mailErrorMessage}
              </div>
            )}
            {mailCorrectionMessage && (
              <div className={styles.correction_message}>
                {mailCorrectionMessage}
              </div>
            )}
            {carrierMessage}
          </Form.Group>

          <Form.Group className={styles.form_group}>
            <Form.Label className={styles.label}>
              パスワード
              <RequireIcon />
            </Form.Label>
            <LablePrompt>{passwordPrompt}</LablePrompt>
            <Form.Control
              type="password"
              value={userInfo.password}
              onChange={handlePasswordChange}
              className={styles.input}
              required
              pattern="^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])[a-zA-Z0-9._?!@#$%^&*\-=]{6,20}$"
              placeholder={passwordPrompt}
              title={passwordPrompt}
            />
            {passwordErrorMessage && (
              <div className={styles.error_message}>
                { passwordErrorMessage }
              </div>
            )}
          </Form.Group>
          
          <Form.Group className={styles.checkbox_botom}>
            <Form.Check
              type="checkbox"
              label={
                <span className={styles.checkbox_label}>
                  利用規約に同意する<ArrowLink link="../term">利用規約</ArrowLink>
                </span>
              }
              required
              className='checkbox'
              defaultChecked={userInfo.termsOfService}
              onChange={() => setUserInfo({ ...userInfo, termsOfService: !userInfo.termsOfService })}
            />
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Check
              type="checkbox"
              label={
                <span className={styles.checkbox_label}>
                  プライバシーポリシーに同意する<ArrowLink link="https://piascore.com/ja/privacy/">プライバシーポリシー</ArrowLink>
                </span>
              }
              required
              defaultChecked={userInfo.privacyPolicy}
              onChange={() => setUserInfo({ ...userInfo, privacyPolicy: !userInfo.privacyPolicy })}
            />
          </Form.Group>

          <Form.Group className="text-center">
            <Button 
              type="submit" 
              variant="primary" 
              size="lg" 
              className={styles.confirm_button}
              disabled={!isFormValid()}
            >
              確認へ
              <span className="fa fa-arrow-circle-right ms-2"></span>
            </Button>
          </Form.Group>
        </Form>
      </div>
    </div>
  );
}

function SignupConfirm({api_base_url}) {
  const [userInfo, _setUserInfo, setSignupState] = useContext(SignupContext);
  const [errorMessages, setErrorMessages] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const clickReturn = async (e) => {
    e.preventDefault();
    setSignupState(0);
  };

  const clickDone = async (e) => {
    e.preventDefault();
    if (isSubmitting) return;
    
    setIsSubmitting(true);
    try {
      const res = await PSSession.create({mailAddress: userInfo.mailAddress, password: userInfo.password, apiBaseUrl: api_base_url});
      if (res) {
        setErrorMessages([]);
        setSignupState(2);
      } else {
        setErrorMessages(["登録エラーが発生しました。"]);
      }
    } catch (error) {
      setErrorMessages(["登録エラーが発生しました。"]);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className={styles.confirm_wrapper}>
      {errorMessages.length > 0 && (
        <Alert variant="warning">
          <ul>
            {errorMessages.map((message, ix) => (
              <li key={ix}>{message}</li>
            ))}
          </ul>
        </Alert>
      )}
      <div className={styles.progress_container}>
        <SignUpTitle />
        <SignUpProgress status={2} />
      </div>
      <div className={styles.confirm_container}>
        <h2 className="fs-6 text-center border-bottom pb-2">こちらの情報でよろしいですか？</h2>
        <table className={styles.confirm_table}>
          <tr>
            <th>メールアドレス</th>
            <td style={{wordBreak: 'break-all'}}><b>{userInfo.mailAddress}</b></td>
          </tr>
          <tr>
            <th>パスワード</th>
            <td>{"*".repeat(userInfo.password.length)}</td>
          </tr>
        </table>
      </div>
      <div className={styles.confirm_action}>
        <Button variant="secondary" className={styles.return_button} size="lg" onClick={clickReturn}>
          戻る
        </Button>
        <Button 
          variant="primary" 
          className={styles.create_button} 
          size="lg" 
          onClick={clickDone}
          disabled={isSubmitting}
        >
          {isSubmitting ? "送信中..." : "会員作成する"} <FontAwesomeIcon icon={faCircleRight} className="ms-2" />
        </Button>
      </div>
    </div>
  );
}

function SignupDone() {
  const [userInfo] = useContext(SignupContext);

  useEffect(() => {
    const doneHtml = PSFlush.jsx2html(
      <>
        <p>
          アカウントを仮登録いたしました。1時間以内に、<b>{userInfo.mailAddress}</b> に送信されたメール内のリンクをクリックいただき、登録を完了してください。
        </p>
        <p>
          メールが届かない場合は、ゴミ箱を確認いただくか、フィルタ設定をご確認の上で <a href="/sign_up">ユーザー登録</a> より再度登録し直してください。
        </p>
      </>
    );

    PSFlush.set({notice: [doneHtml]});
    router.push("/login?mail_address=" + userInfo.mailAddress);
  }, []);

  return null;
}


export default function PSStoreLoginComponent({api_base_url}) {
  const [userInfo, setUserInfo] = useState({
    mailAddress: "",
    password: "",
    termsOfService: false,
    privacyPolicy: false
  });
  const [signupState, setSignupState] = useState(0);

  return (
    <SignupContext.Provider value={[userInfo, setUserInfo, setSignupState]}>
      {signupState === 0 && <SignupForm api_base_url={api_base_url} />}
      {signupState === 1 && <SignupConfirm api_base_url={api_base_url} />}
      {signupState === 2 && <SignupDone />}
    </SignupContext.Provider>
  )
}