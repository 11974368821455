import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Modal, Button, Form, FloatingLabel, Alert } from "react-bootstrap";
import PSSession from '../common/PSSession';
import PSFlush from '../common/PSFlush';
import { router } from '../common/PSRouter';
import styles from './PSStoreUserShowComponent.module.scss'


function EmailModal({show, onHide, apiBaseUrl}) {
  const [mailAddress, setMailAddress] = useState("");
  const [authPassword, setAuthPassword] = useState("");
  const [failed, setFailed] = useState(false);

  const update = async (e) => {
    e.preventDefault();

    try {
      const success = await PSSession.requestMailAddressChange({authPassword, mailAddress, apiBaseUrl});
      console.log("===success", success);
      if (success) {
        PSFlush.set({notice: [`新しいメールアドレス ${mailAddress} に確認メールを送りました。メール内のリンクをクリックして、変更を完了してください。`]});
        setMailAddress("");
        setAuthPassword("");
        setFailed(false);
        onHide(true);
      } else {
        setFailed(true);
      }
    } catch (err) {
      setFailed(true);
    }
  }

  useEffect(() => {
    setFailed(false);
  }, [show]);

  return (
    <Modal show={show} onHide={onHide} centered contentClassName={styles.edit_modal}>
      <Modal.Header closeButton className="p-5 pb-4 border-bottom-0">
        <Modal.Title>
          <h3 className="fw-bold mb-0">
            <span className="fa fa-envelope"></span>
            <span className={styles.title_string}>メールアドレスの変更</span>
          </h3>
        </Modal.Title>
      </Modal.Header>

      {failed && <Alert variant="warning">
        パスワードが正しくないか、新しいメールアドレスが既に利用されている可能性がございます。
      </Alert>}

      <Form onSubmit={(e) => update(e)}>
        <Modal.Body className="p-5 pt-3 pb-3">
          <FloatingLabel controlId="floatingPassword" label="パスワードを入力" className="text-muted pb-3">
            <Form.Control
              type="password"
              required
              placeholder="@"
              value={authPassword}
              onChange={e => setAuthPassword(e.target.value)}
            />
            <div className={styles.forget_link}>
              <span className="fa fa-arrow-right" />
              <a href="/request_password_reset">パスワードを忘れてしまった</a>
            </div>
          </FloatingLabel>
          <FloatingLabel controlId="floatingInput" label="新しいメールアドレスを入力" className="text-muted pb-3">
            <Form.Control
              type="email"
              required
              placeholder="@"
              value={mailAddress}
              onChange={e => setMailAddress(e.target.value)}
            />
          </FloatingLabel>
        </Modal.Body>
      <Modal.Footer className="border-top-0 p-5 pt-2 pb-4">
        <Button type="submit" variant="primary" size="lg" className={styles.edit_button}>
          メールアドレスを変更
        </Button>
        <div className={`mb-1 mt-4 text-center w-100 text-black-50 ${styles.edit_remark}`}>
          新しいメールアドレスに確認メールが届きます
        </div>
      </Modal.Footer>
      </Form>
    </Modal>
  )
}

function PasswordModal({show, onHide, apiBaseUrl}) {
  const [password, setPassword] = useState("");
  const [authPassword, setAuthPassword] = useState("");
  const [failed, setFailed] = useState(false);

  const update = async (e) => {
    e.preventDefault();

    try {
      const success = await PSSession.requestPasswordChange({authPassword, password, apiBaseUrl});
      if (success) {
        PSFlush.set({notice: ['パスワード変更の確認メールが届きます。']});
        setPassword("");
        setAuthPassword("");
        setFailed(false);
        onHide(true);
      } else {
        setFailed(true);
      }
    } catch (err) {
      setFailed(true);
    }
  }

  // useEffect(() => {
  //   setFailed(false);
  // }, [show]);

  return (
    <Modal show={show} onHide={onHide} centered contentClassName={styles.edit_modal}>
      <Modal.Header closeButton className="p-5 pb-4 border-bottom-0">
       <Modal.Title>
          <h3 className="fw-bold mb-0">
            <span className="fa fa-key"></span>
            <span className={styles.title_string}>パスワード変更</span>
          </h3>
        </Modal.Title>
      </Modal.Header>

      {failed && <Alert variant="warning">
        パスワードが正しくない可能性がございます。
      </Alert>}

      <Form onSubmit={(e) => update(e)}>
        <Modal.Body className="p-5 pt-3 pb-3">
          <FloatingLabel controlId="floatingPassword" label="現在のパスワードを入力" className="text-muted pb-3">
            <Form.Control
              type="password"
              required
              placeholder="@"
              value={authPassword}
              onChange={e => setAuthPassword(e.target.value)}
            />
            <div className={styles.forget_link}>
              <span className="fa fa-arrow-right" />
              <a href="/request_password_reset">パスワードを忘れてしまった</a>
            </div>
          </FloatingLabel>
          <FloatingLabel controlId="floatingInput" label="新しいパスワードを入力" className="text-muted pb-3">
            <Form.Control
              type="password"
              required
              pattern="^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])[a-zA-Z0-9._?!@#$%^&*\-=]{6,20}$"
              placeholder="@"
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
            <div className={styles.password_remark}>
              6〜20文字、英大小・数字を最低一つずつ、「._?!@#$%^&*-=」が使用可能です
            </div>
          </FloatingLabel>
        </Modal.Body>
      <Modal.Footer className="border-top-0 p-5 pt-2 pb-4">
        <Button type="submit" variant="primary" size="lg" className={styles.edit_button}>
        パスワードを変更
        </Button>
      </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default function PSStoreUserShowComponent({api_base_url}) {
  const [user, setUser] = useState(null);
  const [emailModalShow, setEmailModalShow] = useState(false);
  const [passwordModalShow, setPasswordModalShow] = useState(false);
  const apiBaseUrl = api_base_url;

  useEffect(() => {
    (async() => {
      const user = await PSSession.getUserInfo({apiBaseUrl});
      setUser(user);
    })();
  }, []);

  if (user === null) {
    return null;
  }
  return (
    <div className={styles.setting_component}>
      <div className={styles.title}>
        <h3 className="fw-bold mb-0 fs-4">
          <span className="fa fa-user"></span>
          <span className={styles.title_string}>会員情報変更</span>
        </h3>
      </div>

      <Container className="pt-3 pb-3">
        <Row className="fs-6 pt-4 pb-4">
          <Col sm={2} className={`${styles.lable} fw-bolder`}>
            メールアドレス
          </Col>
          <Col sm={6}>
            <div className={`${styles.value} act-value fs-6 p-3 rounded-2`}>
              {user && user.mail_address}
            </div>
            <div className="pt-2 text-end">
              <a onClick={() => setEmailModalShow(true)}>メールアドレスの変更</a>
            </div>
          </Col>
        </Row>
        <Row className="fs-6 pt-4 pb-4">
          <Col sm={2} className={`${styles.lable} fw-bolder`}>
            パスワード
          </Col>
          <Col sm={6}>
            <div className={`${styles.value} act-value fs-6 p-3 rounded-2`}>
              ******
            </div>
            <div className="pt-2 text-end">
              <a onClick={() => setPasswordModalShow(true)}>パスワードの変更</a>
            </div>
          </Col>
        </Row>
      </Container>

      <EmailModal show={emailModalShow} onHide={(reload) => {setEmailModalShow(false); if (reload) router.reload();}} apiBaseUrl={apiBaseUrl} />
      <PasswordModal show={passwordModalShow} onHide={(reload) => {setPasswordModalShow(false); if (reload) router.reload();}} apiBaseUrl={apiBaseUrl} />
    </div>
  )
}
