import React, { useState } from 'react';

import { Button, FloatingLabel, Form } from "react-bootstrap";
import styles from './PSStoreRequestPasswordResetComponent.module.scss'
import PSSession from '../common/PSSession';
import PSFlush from '../common/PSFlush';
import { router } from '../common/PSRouter';


export default function PSStoreRequestPasswordResetComponent({api_base_url}) {
  const [mailAddress, setMailAddress] = useState("");

  const resetRequestPassword = async (e) => {
    e.preventDefault();

    const success = await PSSession.requestPasswordReset({mailAddress, apiBaseUrl: api_base_url});
    if (success) {
      PSFlush.set({notice: ['ご登録のメールアドレスに、パスワード再設定リンクを送信いたしました。', '届かない場合は、メールアドレスが間違っているか、登録されていない可能性がございます。']});
    } else {
      PSFlush.set({notice: [PSFlush.jsx2html(
        <><p>ご登録がないか、本登録が完了していません。</p><p>仮登録がお済みの場合、「[Piascore 楽譜ストア] 仮登録のお知らせ」という題名のメール中のリンクをクリックいただき、登録を完了させてください。または、<a href="/sign_up">ユーザー登録</a>より再度登録し直してください。</p></>
      )]});
    }
    router.push("/login");
  }

  return (
    <>
      <div className={`${styles.auth_container} p-4 rounded-5`}>
        <div className="text-center">
          <h3 className="fw-bold mb-0 fs-4">パスワードのリセット</h3>
        </div>
        <div className="mx-auto text-center">
          <div className={styles.prompt}>
            ご登録されているメールアドレスを入力してください
          </div>
        </div>
        <Form className="mx-auto pt-4 fs-6" onSubmit={(e) => resetRequestPassword(e)}>
          <FloatingLabel controlId="floatingInput" label="メールアドレス" className="mb-3 text-muted">
            <Form.Control
              type="email"
              required
              placeholder="@"
              value={mailAddress}
              onChange={e => setMailAddress(e.target.value)}
            />
          </FloatingLabel>

          <Form.Group className="text-center pt-3 pb-3">
            <Button type="submit" variant="primary" className={styles.reset_button}>
            パスワードのリセット
            </Button>
          </Form.Group>
        </Form>
      </div>
    </>
  )
}