import PSAPIClient from '../common/PSAPIClient';

//  ***  Reactアプリ用のセッション管理ライブラリー  ***

let defaultApiBaseUrl = null;

const PSSession = {
  // APIサーバーのURL設定
  setup: ({apiBaseUrl}) =>  {
    defaultApiBaseUrl = apiBaseUrl;
  },

  // ログイン
  //   - 戻り値はログイン成功ならtrue、失敗ならfalse
  login: async function({mailAddress, password, apiBaseUrl = defaultApiBaseUrl}) {
    try {
      const user = await PSAPIClient.post({
        url: `${apiBaseUrl}/users/login`,
        data: {mail_address: mailAddress, password: password},
        throwError: true
      });
      return Boolean(user?.body?.id);
    } catch (err) {
      console.error("===login Error", err);
      throw err;
    }
  },

  // ログアウト
  logout: async function({apiBaseUrl = defaultApiBaseUrl}) {
    try {
      await PSAPIClient.post({
        url: `${apiBaseUrl}/users/logout`,
        throwError: true
      });
    } catch (err) {
      console.error("===logout Error", err);
      throw err;
    }
  },

  // ログイン済みチェックを行う（サーバーに問合せする）
  //   - 戻り値はログイン中ならloginが trueを戻す、してなければ falseを戻す
  checkLogind: async function({apiBaseUrl = defaultApiBaseUrl}) {
    try {
      const login = await PSAPIClient.get({
        url: `${apiBaseUrl}/users/check/login`,
        throwError: true
      });
      return Boolean(login?.body?.login);
    } catch (err) {
      console.error("===checkLogind Error", err);
      throw err;
    }
  },

  // ログインしているユーザーの情報を戻す
  //   - ログインしてない場合はnullを戻す
  getUserInfo: async function({apiBaseUrl = defaultApiBaseUrl}) {
    try {
      const user = await PSAPIClient.get({
        url: `${apiBaseUrl}/users/`,
        throwError: true
      });
      return user?.body || null;
    } catch (err) {
      console.error("===getUserInfo Error", err);
      throw err;
    }
  },

  // 登録済みかどうかのチェック
  //   - 既存アカウントならregisteredが trueを戻す、してなければ falseを戻す
  checkUserRegistered: async function({mailAddress, apiBaseUrl = defaultApiBaseUrl}) {
    try {
      const url = `${apiBaseUrl}/users/check/is_registered?mail_address=${encodeURIComponent(mailAddress)}`;
      console.log(url)
      const registered = await PSAPIClient.get({
        url,
        throwError: true
      });
      return Boolean(registered?.body?.registered);
    } catch (err) {
      console.error("===checkUserRegistered Error", err);
      throw err;
    }
  },

  // 仮登録
  //   - 戻り値はログイン成功ならhash、失敗ならfalse
  create: async function({mailAddress, password, apiBaseUrl = defaultApiBaseUrl}) {
    try {
      const response = await PSAPIClient.post({
        url: `${apiBaseUrl}/users/create`,
        data: {mail_address: mailAddress, password: password},
        throwError: true
      });
      return response?.status?.success === 1;
    } catch (err) {
      console.error("===create Error", err);
      throw err;
    }
  },

  // 本登録
  //   - 戻り値は成功なら 0、既に本登録済みなら 1、無効なら2、その他エラーなら 3
  // activateStatus: {OK: 0, Registered: 1, Invalid: 2, Error: 3},
  activate: async function({hash, apiBaseUrl = defaultApiBaseUrl}) {
    try {
      const response = await PSAPIClient.post({
        url: `${apiBaseUrl}/users/activate`,
        data: {hash},
        throwError: true
      });      
      // return PSSession.activateStatus.OK;
      return response
    } catch (err) {
      // console.error("===activate Error", err);
      // const errorName = err.toString().replace(/.*=(.+)/, "$1");
      // if (errorName === "Conflict") {
      //   return PSSession.activateStatus.Registered;
      // }
      // if (errorName === "Bad Request") {
      //   return PSSession.activateStatus.Invalid;
      // }
      throw err;
    }
  },

  // パスワードのリセット要求
  //   - 戻り値は成功ならtrue、失敗ならfalse
  requestPasswordReset: async function({mailAddress, apiBaseUrl = defaultApiBaseUrl}) {
    try {
      const response = await PSAPIClient.post({
        url: `${apiBaseUrl}/users/password/reset/request`,
        data: {mail_address: mailAddress},
        throwError: true
      });
      return Boolean(response?.status?.success);
    } catch (err) {
      console.error("===requestPasswordReset Error", err);
      throw err;
    }
  },

  // パスワードのリセット用の認証
  //   - 戻り値は成功なら 0、時間切れなら 1、その他エラーなら 2
  authPasswordResetStatus: {OK: 0, Timeout: 1, Error: 2},
  authPasswordReset: async function({hash, apiBaseUrl = defaultApiBaseUrl}) {
    try {
      await PSAPIClient.post({
        url: `${apiBaseUrl}/users/password/reset/auth`,
        data: {hash},
        throwError: true
      });
      return PSSession.authPasswordResetStatus.OK;
    } catch (err) {
      console.error("===authPasswordReset Error", err);
      const errorName = err.toString().replace(/.*=(.+)/, "$1");
      if (errorName === "Request Timeout") {
        return PSSession.authPasswordResetStatus.Timeout;
      }
      throw err;
    }
  },

  // パスワードのリセット用の実行
  //  - 戻り値は成功ならtrue、失敗ならfalse
  updatePasswordReset: async function({password, apiBaseUrl = defaultApiBaseUrl}) {
    try {
      const response = await PSAPIClient.put({
        url: `${apiBaseUrl}/users/password/reset/update`,
        data: {password},
        throwError: true
      });
      return Boolean(response.status?.success);
    } catch (err) {
      console.error("===updatePasswordReset Error", err);
      throw err;
    }
  },

  // メールアドレス変更の要求
  //   - 戻り値は成功ならtrue、失敗ならfalse
  requestMailAddressChange: async function({authPassword, mailAddress, apiBaseUrl = defaultApiBaseUrl}) {
    try {
      const response = await PSAPIClient.put({
        url: `${apiBaseUrl}/users/mail_address/change/request`,
        data: {auth_password: authPassword, mail_address: mailAddress},
        throwError: true
      });
      return Boolean(response?.status?.success);
    } catch (err) {
      console.error("===requestMailAddressChange Error", err);
      throw err;
    }
  },

  // メールアドレス変更の実行
  //   - 戻り値は成功なら新しいメールアドレス、失敗ならnull
  confirmMailAddressChange: async function({hash, apiBaseUrl = defaultApiBaseUrl}) {
    try {
      const response = await PSAPIClient.post({
        url: `${apiBaseUrl}/users/mail_address/change/confirm`,
        data: {hash: hash},
        throwError: true
      });
      return response?.body?.mail_address || null;
    } catch (err) {
      console.error("===confirmMailAddressChange Error", err);
      throw err;
    }
  },

  // パスワード変更の要求
  //   - 戻り値は成功ならtrue、失敗ならfalse
  requestPasswordChange: async function({authPassword, password, apiBaseUrl = defaultApiBaseUrl}) {
    try {
      const response = await PSAPIClient.put({
        url: `${apiBaseUrl}/users/password/change/request`,
        data: {auth_password: authPassword, password: password},
        throwError: true
      });
      return Boolean(response?.status?.success);
    } catch (err) {
      console.error("===requestPasswordChange Error", err);
      throw err;
    }
  },

  // パスワード変更の実行
  //   - 戻り値は成功ならtrue、失敗ならfalse
  confirmPasswordChange: async function({hash, apiBaseUrl = defaultApiBaseUrl}) {
    try {
      const response = await PSAPIClient.post({
        url: `${apiBaseUrl}/users/password/change/confirm`,
        data: {hash: hash},
        throwError: true
      });
      return Boolean(response.status?.success);
    } catch (err) {
      console.error("===confirmPasswordChange Error", err);
      throw err;
    }
  },

  // 退会
  //   - 戻り値は成功ならtrue、失敗ならfalse
  unsubscribe: async function({reasonType, message, apiBaseUrl = defaultApiBaseUrl}) {
    try {
      const response = await PSAPIClient.delete({
        url: `${apiBaseUrl}/users/unsubscribe`,
        data: {reason_type: reasonType, message},
        throwError: true
      });
      return Boolean(response.status?.success);
    } catch (err) {
      console.error("===unsubscribe Error", err);
      throw err;
    }
  },
}

export default PSSession;
