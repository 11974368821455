import React, { useState, useEffect } from 'react';
import { Alert } from "react-bootstrap";
import PSFlush from '../common/PSFlush';

function Flush({variant, messages}) {
  if (messages) {
    return (
      <Alert variant={variant}>
      <ul>
        {messages.map((message, ix) =>
          PSFlush.isHtml(message) ?
            <li key={ix} dangerouslySetInnerHTML={message}></li> :
            <li key={ix}>{message}</li>)}
      </ul>
    </Alert>
    )
  } else {
    return null;
  }
}

export default function PSStoreFlushComponent() {
  const [flush, setFlush] = useState({});

  useEffect(() => {
    const value = PSFlush.get();
    if (value) { setFlush(value) }
  }, []);

  return (
    <>
      <Flush variant="info" messages={flush.notice} />
      <Flush variant="warning" messages={flush.error} />
    </>
  )
}