import React from 'react';
import './PSUserPurchaseHistoryComponent.scss'; // ここにCSSファイルをインポート

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { library }         from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
library.add(fas, far, fab)

export default function PSUserPurchaseHistoryComponent({ purchase_logs_json, page, totalPage }) {

  const pjs   = JSON.parse(purchase_logs_json);

  let difficultyText = (difficulty) => {
    switch(difficulty) {
      case 101:
        return "入門"
      case 102:
        return "入門〜初級"        
      case 1:
        return "初級"
      case 2:
       return "初〜中級"
      case 3:
        return "中級"
      case 4:
        return "中〜上級"
      case 5:
        return "上級"
      case 18:
        return "その他"
    }
        
    return ""
  }

  //console.log(pj[0].score.score_regions.filter(r => r.region_id==360)[0].name);
  pjs.forEach(pj => {
    if (pj.score != null) {
      if(pj.score.score_regions.length != 0) {
        pj["name"] = pj.score.score_regions.filter(r => r.region_id==360)[0].name
        pj["instrument_name"] = pj.score.instrument.instrument_regions.filter(r => r.region_id==360)[0].name
        pj["difficultyText"] = difficultyText(pj.score.difficulty)
      }else{
        pj.score = null
      }
    }
        
    pj["insert_date"] = pj.insert_time.replace(/(\d{4})-(\d{2})-(\d{2})T(\d{2}:\d{2}):\d{2}\.\d{3}\+\d{2}:\d{2}/, '$1年$2月$3日');
    pj["insert_time"] = pj.insert_time.replace(/(\d{4})-(\d{2})-(\d{2})T(\d{2}:\d{2}):\d{2}\.\d{3}\+\d{2}:\d{2}/, '$4');
  });
  
  // let page          = 5
  // let totalPage     = 10
  let maxPageRange  = 2

  let startPage = Math.max(page - maxPageRange, 1)
  let endPage   = Math.min(page + maxPageRange, totalPage)
  
  let pagenation = (
    <>
      <div style={{display:"flex", justifyContent:"center"}}>
        <nav aria-label="Page navigation example">
          <ul className="pagination">
            <li className="page-item">
              <a className="page-link" href={`/user/history`} aria-label="Previous">
                <span aria-hidden="true">&laquo;</span>
              </a>
            </li>
            
            {
              [...Array(endPage-startPage+1).keys()].map((i) => {
                let p = startPage+i
                return (
                  <li key={p} className={`page-item ${p==page ? "active" : ""}`}>
                    <a class="page-link" href={`/user/history?page=${p}`}>{ p }</a>
                  </li>
                )
              })
            }
            
            <li className="page-item">
              <a className="page-link" href={`/user/history?page=${totalPage}`} aria-label="Next">
                <span aria-hidden="true">&raquo;</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </>
  )
  
  return (
    <>
      { 
        pjs.length > 0 ? (
          <>
            <div style={{margin:"30px 0 30px 0"}}>
              { pagenation }
            </div>

            <div class="pc_hidden" style={{margin:"20px 20px", boxShadow: "0px 4px 23px 0px rgba(0, 0, 0, 0.15)", borderRadius:13}}>
              {
                pjs.map((pj, index) => (
                  <a key={index} class="row g-0" style={{color:"#444", padding:"15px 5px", borderBottom:"1px solid #E5E5E5"}} target="_blank" href={`/scores/${pj.score_id}`}>
                    <div class="col-1" style={{fontSize:11, width:30, display: "flex", alignItems: "center", justifyContent:"flex-end"}}>{ index + (page-1) * 50 + 1}.</div>
                    <div class="col-2 text-center">
                      {
                        pj.score != null ?
                        (
                          <img style={{width:50, border:"1px solid #E9E9E9"}} src={`https://image.piascore.com/store/coverimage/site_l/${pj.score.sanitized_base_filename}.png`} />
                        )
                        :
                        ""
                      }
                    </div>
                    <div class="col" style={{margin:"0 0 0 10px"}}>
                      <div style={{display:"flex", alignItems:"center"}}>
                        <div style={{color:"#A0A0A0", fontSize:11}}>{pj.insert_date}に購入しました</div>
                        {/*
                          (pj.score != null && pj.sales_price_with_tax != 0) ?
                          (
                            <div style={{marginLeft:"auto"}}><a style={{fontSize:11, fontWeight:"bold"}} target="_blank" href={`/scores/${pj.score_id}/receipt`}><span style={{fontSize:"80%"}}>領収書</span></a></div>
                          )
                          :
                          ""
                        */}
                      </div>

                      {
                        pj.score != null ?
                        (
                          <>
                            <div style={{margin:"9px 0 0 0"}}><span style={{fontSize:13, fontWeight:"bold", wordBreak: "break-word"}}>{ pj.name}</span></div>
                        
                            <div style={{margin:"4px 0 0 0"}}><span style={{color:"#5B5B5B", fontSize:12}}>{ pj.instrument_name } / { pj.difficultyText } </span></div>
                          </>
                        )
                        :
                        (
                          <>
                            <div style={{margin:"21px 0 0 0"}}><span style={{ color:"#A0A0A0", fontStyle:"italic", fontSize:12 }}>楽譜が削除されました</span></div>
                          </>
                        )
                      }                      
                    </div>
                    
                    <div class="col-1" style={{fontSize:11, width:30, display: "flex", alignItems: "center", justifyContent:"center"}}><span className="fas fa-chevron-right" /></div>
                  </a>        
                ))
              }
            </div>

            
            <div class="container sp_hidden" style={{boxShadow: "0px 4px 23px 0px rgba(0, 0, 0, 0.15)", borderRadius:13}}>
              {
                pjs.map((pj, index) => (
                  <div key={index} class="row g-0" style={{padding:"23px", borderBottom:"1px solid #E5E5E5"}}>
                    <div class="col-1" style={{width:40, display: "flex", alignItems: "center", justifyContent:"flex-end"}}>{ index + (page-1) * 50 + 1}.</div>
                    <div class="col-2 text-center">
                      {
                        pj.score != null ?
                        (
                          <img style={{width:97, border:"1px solid #E9E9E9"}} src={`https://image.piascore.com/store/coverimage/site_l/${pj.score.sanitized_base_filename}.png`} />
                        )
                        :
                        ""
                      }
                    </div>
                    <div class="col" style={{margin:"0 0 0 0px"}}>
                      <div style={{display:"flex", alignItems:"center"}}>
                        <div style={{color:"#A0A0A0", fontSize:14}}>{pj.insert_date}に購入しました</div>
                        {
                          (pj.score != null && pj.sales_price_with_tax != 0) ?
                          (
                            <div style={{marginLeft:"auto"}}><a style={{fontSize:13, fontWeight:"bold"}} target="_blank" href={`/scores/${pj.score_id}/receipt`}>領収書</a></div>
                          )
                          :
                          ""
                        }
                      </div>

                      {
                        pj.score != null ?
                        (
                          <>
                            <div style={{margin:"21px 0 0 0"}}><a style={{fontSize:16, fontWeight:"bold"}} target="_blank" href={`/scores/${pj.score_id}`}>{ pj.name}</a></div>
                        
                            <div style={{margin:"8px 0 0 0"}}><span style={{color:"#5B5B5B", fontSize:14}}>{ pj.instrument_name } / { pj.difficultyText } </span></div>
                          </>
                        )
                        :
                        (
                          <>
                            <div style={{margin:"21px 0 0 0"}}><span style={{ color:"#A0A0A0", fontStyle:"italic", fontSize:16 }}>楽譜が削除されました</span></div>
                          </>
                        )
                      }
                      
                    </div>
                  </div>              
                ))
              }
            </div>

            <div style={{margin:"30px 0 30px 0"}}>
              { pagenation }
            </div>
            
            
            { /*
            <table class="table">
              <thead>
                <tr>
                  <th className="insert_time" scope="col">ご購入日時</th>
                  <th className="name" scope="col">楽譜</th>
                  <th className="receipt" scope="col">領収書</th>
                </tr>
              </thead>
              <tbody>
                { 
                  pjs.map((pj, index) => (
                    <tr key={index}>
                      <td className="insert_time">{pj.insert_date} { pj.insert_time}</td>
                      <td className="name" ><a target="_blank" href={`/scores/${pj.score_id}`}>{ pj.name}</a></td>
                      <td className="receipt" ><a target="_blank" href={`/scores/${pj.score_id}/receipt`}><span className="fas fa-receipt" /><br /><span style={{fontSize:"80%"}}>領収書</span></a></td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
            */}
          </>
        )
        :
        (
          <div style={{margin:"30px 30px", textAlign:"center"}}>
            履歴がありません
          </div>
        )
      }
    </>
  );
}
