import React from 'react';
import { Button, Container, Row, Col } from "react-bootstrap";
import PSStoreLogoutComponent from './PSStoreLogoutComponent';
import styles from './PSStoreUserComponent.module.scss'


export default function PSStoreUserComponent({api_base_url, can_unsubscribe}) {
  return (
    <div className={styles.setting_component}>
      <div className={styles.title}>
        <h3 className="fw-bold mb-0 fs-4">
          <span className="fa fa-user"></span>
          <span className={styles.title_string}>会員情報</span>
        </h3>

        <div className={styles.logout}>
          <PSStoreLogoutComponent api_base_url={api_base_url} />
        </div>
      </div>

      <Container>
        <Row className="pt-5 pb-3">
          <Col sm={true} className="text-center">
            <Button variant="outline-primary" className={styles.button} href="/user/history">
              購入履歴を見る
            </Button>
          </Col>
          <Col sm={true} className="text-center">
            <Button variant="outline-primary" className={styles.button} href="/user/show">
              会員情報を変更する
            </Button>
          </Col>
        </Row>
      </Container>

      <div className={`text-end pt-5 ${styles.unsubscribe}`}>
        {
          can_unsubscribe ? (
            <a href="/user/form_unsubscribe">退会する</a>
          ) 
          : 
          (
            <div className="text-muted" style={{fontSize: "0.9em"}}>
              <span style={{cursor: "not-allowed"}}>退会する</span>
              <div className="mt-1" style={{fontSize: "0.9em"}}>
                ※ コンビニ印刷申し込み中のため退会できません。
              </div>
            </div>
          )
        }
      </div>
    </div>
  )
}
