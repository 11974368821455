import React, { useEffect } from 'react';
import PSSession from '../common/PSSession';
import PSFlush from '../common/PSFlush';
import PSURLSearchParams from '../common/PSURLSearchParams';
import { router } from '../common/PSRouter';

export default function PSStoreActivationComponent({api_base_url}) {

  useEffect(() => {
    const errorHtml = PSFlush.jsx2html(
      <>登録期限内に登録が完了しなかったか、アクセスエラーです。<a href="/sign_up">ユーザー登録</a>より、登録し直してください。</>
    );

    (async() => {
      const hash = PSURLSearchParams.get("key");

      let mailAddress = null
      if (hash) {
        try {
          const response = await PSSession.activate({hash, apiBaseUrl: api_base_url});
          console.log(response)
          mailAddress = response.body.user.mail_address
          PSFlush.set({notice: ['アカウントの登録が完了しました。ログインして下さい']});
        } catch (err) {
          const errorName = err.toString().replace(/.*=(.+)/, "$1");
          if (errorName === "Conflict") {
            PSFlush.set({error: ['すでに本登録済みです。ログインしてください。']});
          }
          if (errorName === "Bad Request") {
            PSFlush.set({error: [errorHtml]});
          }else{
            PSFlush.set({error: [errorHtml]});
          }
        }
      } else {
        PSFlush.set({error: [errorHtml]});
      }
      if (mailAddress) {
        router.push(`/login?mail_address=${mailAddress}`);
      } else {
        router.push('/login');
      }
    })()
  }, []);


  return null;
}