import React, {useEffect, useState} from 'react';
import { Breadcrumb } from "react-bootstrap";
import styles from './PSStoreBreadcrumb.module.scss'


const BreadcrumbTitles = {
  "/": "トップ",
  "/user": "会員情報",
  "/user/show": "会員情報変更",
  "/user/history": "購入履歴"
}

const hierarchicalPathnames = (pathname) => {
  if (pathname === "/") return ["/"];

  const paths = pathname.split("/");
  let pathnames = [];
  paths.forEach((path, ix) => {
    pathnames.push(ix <= 1 ? `/${path}` : `${pathnames[ix - 1]}/${path}`)
  })
  return pathnames;
}

export default function PSStoreBreadcrumb() {
  const [paths, setPaths] = useState([]);

  useEffect(() => {
    setPaths(hierarchicalPathnames(location.pathname));
  }, []);

  const customizeStyle = {
    '--bs-breadcrumb-divider': "'›'"
  }
  return (
    <h2 className={styles.breadcrumbs_top}>
      <Breadcrumb style={customizeStyle}>
        {paths.map((path, ix) =>
          ix + 1 === paths.length ?
          <Breadcrumb.Item active key={ix}>{BreadcrumbTitles[path]}</Breadcrumb.Item> :
          <Breadcrumb.Item href={path} key={ix}>{BreadcrumbTitles[path]}</Breadcrumb.Item>
        )}
      </Breadcrumb>
    </h2>
  );
}