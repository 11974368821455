
export default class PSAPIClient {

  static async get({url, throwError}) {

    if (url == null) { throw new Error("url is null") }

    const response = await fetch(url, {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    });
    //console.debug(response);

    const res = await response.json()
    if (throwError && !response.ok) { throw new Error("Invalid access message=" + res.message) }

    return res
  }

  static async post({url, data}) {

    if (url == null) { throw new Error("url is null") }

    const params = {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      //credentials: "same-origin",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(data),
    }
    
    if(data) { params.body = JSON.stringify(data) }

    const response = await fetch(url, params);

    const res = await response.json()

    //if(response.status !== 200) { throw new Error("Invalid access message=" + res.message) }
    if(!response.ok) { throw new Error("Invalid access message=" + res.message) }

    return res
  }

  /**
   *
   * @param {*} param0
   * @returns
   */
  static async postMultipart({url, formData}) {

    if (url == null) { throw new Error("url is null") }

    //console.log(formData)

    const response = await fetch(url, {
      method: "POST",
      body: formData,

      mode: "cors",
      cache: "no-cache",
      //credentials: "same-origin",
      credentials: "include",
      // headers: {
      //   //"Content-Type": "application/json",
      //   'Content-Type': 'multipart/form-data',
      //   // 'Content-Type': 'application/x-www-form-urlencoded',
      // },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    });

    const res = await response.json()

    //if(response.status !== 200) { throw new Error("Invalid access message=" + res.message) }
    if(!response.ok) { throw new Error("Invalid access message=" + res.message) }

    return res
  }

  static async put({url, data}) {

    const params = {
      method: "PUT",
      mode: "cors",
      cache: "no-cache",
      //credentials: "same-origin",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    }

    if(data) { params.body = JSON.stringify(data) }
    
    const response = await fetch(url, params);
  
    const res = await response.json()

    return res
  }

  static async delete({url, data}) {

    const params = {
      method: "DELETE",
      mode: "cors",
      cache: "no-cache",
      //credentials: "same-origin",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    }

    if(data) { params.body = JSON.stringify(data) }
    
    const response = await fetch(url, params);
  
    const res = await response.json()

    return res
  }

}