import React, { useEffect } from 'react';
import PSSession from '../common/PSSession';
import PSFlush from '../common/PSFlush';
import PSURLSearchParams from '../common/PSURLSearchParams';
import { router } from '../common/PSRouter';

export default function PSStoreConfirmChangePassword({api_base_url}) {

  useEffect(() => {
    (async() => {
      const hash = PSURLSearchParams.get("v");
      if (hash) {
        try {
          const ok = await PSSession.confirmPasswordChange({hash, apiBaseUrl: api_base_url});
          if (ok) {
            PSFlush.set({notice: ['パスワードが変更されました']});
          router.push("/user");
            return;
          }
        } catch (err) {
          PSFlush.set({error: ['パスワードが変更できませんでした。再度、パスワード変更の手続きを行ってください。']});
        }
      }
      PSFlush.set({error: ['パスワードが変更できませんでした。再度、パスワード変更の手続きを行ってください。']});
      router.push("/user");
    })()
  }, []);


  return null;
}