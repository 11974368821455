import React, { useEffect, useState } from 'react';
import { Button, FloatingLabel, Form } from "react-bootstrap";
import PSSession from '../common/PSSession';
import PSFlush from '../common/PSFlush';
import PSURLSearchParams from '../common/PSURLSearchParams';
import { router } from '../common/PSRouter';
import styles from './PSStorePasswordResetComponent.module.scss'

export default function PSStorePasswordResetComponent({api_base_url}) {
  const [password, setPassword] = useState("");

  const resetPassword = async (e) => {
    e.preventDefault();

    const success = await PSSession.updatePasswordReset({password, apiBaseUrl: api_base_url});
    if (success) {
      PSFlush.set({notice: ['パスワード変更に成功しました']});
    } else {
      PSFlush.set({error: ['パスワードが変更できませんでした。再度、パスワードのリセット手続きを行ってください。']});
    }
    router.push("/login");
  }

  useEffect(() => {
    (async() => {
      const hash = PSURLSearchParams.get("verification_text");

      if (hash) {
        try {
          const status = await PSSession.authPasswordReset({hash, apiBaseUrl: api_base_url});
          if (status === PSSession.authPasswordResetStatus.OK) {
            // TODO
          } else if (status === PSSession.authPasswordResetStatus.Timeout) {
            PSFlush.set({error: ['猶予期間が過ぎています']});
            router.push("/login");
          } else {
            PSFlush.set({error: ['無効なアカウントです']});
            router.push("/login");
          }
        } catch (err) {
          PSFlush.set({error: ['エラーが発生しました（パスワードの再設定）']});
          router.push("/login");
        }
      } else {
        PSFlush.set({error: ['無効なアカウントです']});
        router.push("/login");
      }
    })()
  }, []);


  return (
    <>
    <div className={`${styles.auth_container} p-5 rounded-5`}>
      <div className="text-center">
        <h3 className="fw-bold mb-0 fs-4">パスワードの再設定</h3>
      </div>
      <div className="mx-auto text-center">
        <div className={styles.prompt}>
          新しいパスワードを入力してください
        </div>
      </div>
      <Form className="mx-auto mt-4 pt-4 fs-6" onSubmit={(e) => resetPassword(e)}>
        <FloatingLabel controlId="floatingPassword" label="パスワード" className="text-muted">
          <Form.Control
            type="password"
            required
            pattern="^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])[a-zA-Z0-9._?!@#$%^&*\-=]{6,20}$"
            placeholder="パスワード"
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
        </FloatingLabel>

        <div className={styles.prompt2}>
        6〜20文字、英大小・数字を最低一つずつ、「._?!@#$%^&*-=」が使用可能です
        </div>

        <Form.Group className="text-center mt-5 pb-2">
          <Button type="submit" variant="primary" size="lg" className={styles.reset_button}>
          パスワードの再設定
          </Button>
        </Form.Group>
       </Form>
    </div>
  </>

  )
}