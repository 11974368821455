import React from 'react';
import { useState, useEffect } from 'react'
import axios from 'axios';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { library }         from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
library.add(fas, far, fab)

import styles from './PSStorePaymentComponent.module.scss'


import CreditComponent from './payment/credit.jsx'
//import CreditComponent from '../../../../PiascoreScoreAPI/example/frontend/components/purchase/credit.jsx'
//import CreditComponent from './payment/credit.js'


import { Button } from "react-bootstrap"
// import Popover from 'react-bootstrap/Popover'

// import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

function PaymentComponent({score_id, api_base_url, token_api_key}) {

  //const endpoint      = api_base_url + "/purchase"
  // const token_api_key = process.env.REACT_APP_TOKEN_API_KEY ? process.env.REACT_APP_TOKEN_API_KEY : process.env.NEXT_PUBLIC_TOKEN_API_KEY

  const onSubmit = ({}) => {
    console.log("onSubmit")
    //closePayment();
  }

  // const closePayment = () => {
  //   var myOffcanvas = document.getElementById('offcanvasPayment')
  //   //console.log(myOffcanvas)
  //   var bsOffcanvas = new bootstrap.Offcanvas(myOffcanvas)
  //   //console.log(bsOffcanvas)
  //   bsOffcanvas.hide()
  // }

  const didGetToken = ({token, message}) => {
    console.log("token=" + token)
  }

  const didPayment = ({order_id}) => {
    console.log("order_id2=" + order_id)
    window.location.href = `/scores/${score_id}?downloaded=1`;
  }

  const didError = ({error}) => {
    console.log("error=" + error)
  }

  return (
    <>
{/* 
      <div>
        決済方法を選択してください
      </div>
 */}

      <div className="card">
        
        <div className="card-header" style={{"fontWeight":"bold", "fontSize":15}}>
          <FontAwesomeIcon icon="fa-regular fa-credit-card" />
          <span style={{margin:"0 0 0 3px"}}>
            クレジットカード
          </span>
        </div>

        <div className="card-body" style={{padding:"20px 0"}}>
          <CreditComponent score_id={score_id} api_base_url={api_base_url} token_api_key={token_api_key} onSubmit={onSubmit} didGetToken={didGetToken} didPayment={didPayment} didError={didError} isDebug={true} /> 
        </div>

      </div>

      <div style={{textAlign:"center", fontSize:11, margin:"20px 0 0 0"}}>
        <span style={{color:"#BBB"}}>Powered by </span><a target="_blank" href="https://www.veritrans.co.jp/payment/">VeriTrans4G</a>
      </div>


{/* 
      <div className="accordion" id="payment-accordion">

        <div className="accordion-item">
          <h2 className="accordion-header" id="headingOne">
            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
              クレジットカード
            </button>
          </h2>
          <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#payment-accordion">
            <div className="accordion-body">
              <CreditComponent score_id={score_id} endpoint={endpoint} token_api_key={token_api_key} didGetToken={didGetToken} didPayment={didPayment} didError={didError} isDebug={true} /> 
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingTwo">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              電子マネー（PayPay、LINE）
            </button>
          </h2>
          <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#payment-accordion">
            <div className="accordion-body">
            </div>
          </div>
        </div>

        <div className="accordion-item">
          <h2 className="accordion-header" id="headingThree">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
            キャリア決済
            </button>
          </h2>
          <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#payment-accordion">
            <div className="accordion-body">
            </div>
          </div>
        </div>
      </div>
 */}

    </>
  )
}

function PSStorePaymentInfoComponent({ score, title, thumbnail_url }) {

  return (
    <div style={{display:"flex"}}>
      <div>
        <img src={thumbnail_url} style={{width:80, border:"1px solid #DDD", boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.2)"}} />
      </div>
      <div className="" style={{margin:"0 0 0 20px"}}>
        <div style={{fontSize:14, fontWeight:"bold"}}>{title}</div>
        <div style={{color:"#cf431e"}}>
          <span style={{fontSize:26, fontWeight:"bold"}}>{score.price_str_with_tax}</span>
          <span style={{fontSize:12}}>(税込)</span>
        </div>
      </div>
    </div>
  )
}

export default function PSStorePaymentComponent({ score_json, title, thumbnail_url, api_base_url, token_api_key }) {

  const score   = JSON.parse(score_json);
  // console.log(JSON.stringify(score));

  useEffect(() => {
    (async () => {
    })()
  })
  

  return (
    <div className={styles.PSStorePaymentComponent}>
      <button className="btn" data-bs-toggle="offcanvas" href="#offcanvasPayment" role="button" aria-controls="offcanvasPayment" style={{color:"#ffffff", background:"#cf431e", fontSize:15, width:152, height:47}}>
        購入する
      </button>

      {/* <div className="offcanvas offcanvas-end show" tabIndex="-1" id="offcanvasPayment" aria-labelledby="offcanvasPaymentLabel" style={{textAlign:"left"}}> */}
      <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasPayment" aria-labelledby="offcanvasPaymentLabel" style={{textAlign:"left"}}>
      {/* <div className="offcanvas show offcanvas-end" tabIndex="-1" id="offcanvasPayment" aria-labelledby="offcanvasPaymentLabel"> */}
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasPaymentLabel">お支払い</h5>
          <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">

          <div>
            <PSStorePaymentInfoComponent score={score} title={title} thumbnail_url={thumbnail_url} /> 
          </div>

          <div style={{margin:"20px 0 0 0"}}>
            <PaymentComponent score_id={score.score_id} api_base_url={api_base_url} token_api_key={token_api_key} />
          </div>

         </div>
      </div>

    </div>
  )
}
